"use client";

import clsx from "clsx";
import { ReactElement, useState } from "react";
import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { TextButton, TextButtonProps } from "../TextButton";

export type PropsT = {
  title?: string;
  subtitle?: string | ReactElement;
  type?: "success" | "warning" | "error";
  variant?: "default" | "snug";
  dismiss?: () => void;
  dismissable?: boolean;
  primaryButton?: ButtonProps;
  secondaryButton?: TextButtonProps;
  "data-testid"?: string;
  className?: string;
};

export const InlineAlert = (props: PropsT) => {
  const {
    "data-testid": dataTestId,
    title,
    subtitle,
    type,
    dismiss,
    dismissable = true,
    primaryButton,
    secondaryButton,
    variant = "default",
    className = "",
  } = props;

  const [hide, setHide] = useState(false);

  let color = "";
  let icon = "";
  let iconStyle = "";

  switch (type) {
    case "success":
      color = "bg-[#F6FFED] border-[#B7EB8F]";
      icon = "check-circle-fill";
      iconStyle = "fill-[#52C41A]";
      break;
    case "error":
      color = "bg-[#FFF2F0] border-[#FFCCC7]";
      icon = "x-circle-fill";
      iconStyle = "fill-[#FF4D4F]";
      break;
    case "warning":
      color = "bg-[#FFFBE6] border-[#FFE58F]";
      icon = "warning-circle-fill";
      iconStyle = "fill-[#FAAD14]";
      break;
    default:
      color = "bg-gray-100 border-gray-900";
      icon = "info-fill";
      iconStyle = "fill-gray-900";
  }

  return (
    <div
      className={clsx(
        "group pointer-events-auto relative flex w-full flex-col gap-4 overflow-hidden rounded-lg border",
        {
          [color]: color,
          "collapse hidden": hide,
          "py-4 pl-4 pr-12": variant === "default",
          "pr-4": !dismissable,
          "p-2": variant === "snug",
          [className]: className,
        }
      )}
      data-testid={dataTestId || "inline-alert"}
    >
      <div className="mt-0.5 flex w-full flex-row gap-x-2">
        <Icon icon={icon} iconStyle={iconStyle} size={"20"} />

        <div>
          {title && (
            <p className="font-gilroy text-base font-semibold text-gray-900">
              {title}
            </p>
          )}
          {subtitle && (
            <p
              className={clsx("font-gilroy text-sm font-medium text-gray-900", {
                "mt-2": !!title,
              })}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>

      {dismissable && (
        <div
          className={clsx("absolute", {
            "right-2 top-2": variant === "default",
            "right-0 top-1": variant === "snug",
          })}
        >
          <Button
            icon="x"
            color="transparent"
            data-testid="dismiss-button"
            customIconSize={variant === "snug" ? "14" : "20"}
            onClick={() => {
              dismiss?.();
              setHide(true);
            }}
          />
        </div>
      )}

      {Boolean(primaryButton || secondaryButton) && (
        <div className="flex flex-row items-center justify-end gap-x-3">
          {secondaryButton && <TextButton {...secondaryButton} />}
          {primaryButton && <Button {...primaryButton} />}
        </div>
      )}
    </div>
  );
};
