export function debounce<T extends unknown[], U>(
  fn: (...args: T) => U,
  ms = 300
) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  return function (...args: T): void {
    const later = () => {
      timeoutId = null;
      fn(...args);
    };
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(later, ms);
  };
}
