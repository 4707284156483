import clsx from "clsx";

export type PropsT = {
  value: boolean;
  disabled?: boolean;
  onClick?: () => void;
  number?: string | number;
  label?: string;
  multiSelect?: boolean;
  "data-testid"?: string;
};

export const RadioInput = (props: PropsT) => {
  const {
    value,
    onClick,
    disabled,
    label,
    number,
    multiSelect,
    "data-testid": dataTestId,
  } = props;

  return (
    <div
      className="group flex flex-row items-center hover:cursor-pointer"
      onClick={() => (!disabled ? onClick?.() : null)}
      data-testid={dataTestId || "radio-input"}
    >
      <div
        className={clsx(
          "flex flex-shrink-0 flex-grow-0 flex-row items-center justify-center rounded-full border-2",
          {
            "border-gray-900": value && !disabled,
            "border-gray-300 group-hover:border-gray-800": !value && !disabled,
            "cursor-not-allowed border-gray-300": disabled,
            "border-2 border-gray-900 bg-gray-900":
              value && number !== undefined,
            "border-2 border-gray-500 bg-white":
              !value && number !== undefined && multiSelect,
            "h-5 w-5": !multiSelect,
            "h-6 w-6": multiSelect,
          }
        )}
        // onClick={() => (!disabled ? onClick?.() : null)}
      >
        {number !== undefined && (
          <p className="pt-px text-xs text-white">{number}</p>
        )}
        {number === undefined && (
          <div
            className={clsx(
              "flex h-2.5 w-2.5 flex-shrink-0 flex-grow-0 rounded-full",
              {
                "bg-gray-900": value && !disabled,
                "bg-white": !value && !disabled,
                "cursor-not-allowed bg-gray-300": disabled && value,
                "cursor-not-allowed bg-white": disabled && !value,
              }
            )}
          />
        )}
      </div>
      {label && (
        <div className="ml-2 text-sm font-medium text-gray-900">{label}</div>
      )}
    </div>
  );
};
