import { Avatar, Button, SelectInput, Switch } from "@repo/ui";
import clsx from "clsx";
import { useAuth } from "../../../../state/AuthContexts";

export interface IUserTableProps {
  teammates: (ContactType | UserType)[];
  handleChangeRole: (val: UserType) => void;
  handleDisableTeammate: (val: UserType) => void;
  handleRemoveTeammate: (val: UserType | ContactType) => void;
  handleRemoveContact: (val: UserType | ContactType) => void;
  deleteLoading?: number | null;
}

export default function UserTable(props: IUserTableProps) {
  const { user } = useAuth();
  const {
    teammates,
    handleChangeRole,
    handleDisableTeammate,
    handleRemoveTeammate,
    handleRemoveContact,
    deleteLoading,
  } = props;

  const roleOptions = [
    {
      label: "Admin",
      value: "owner",
      description:
        "Can approve and decline bills. Can manage Workspace settings, contacts, roles, payments, and billing.",
    },
    {
      label: "Controller",
      value: "admin",
      description:
        "Can approve and decline bills. Can manage Workspace settings, contacts, payments and billing. Cannot control roles.",
    },
    {
      label: "Accountant",
      value: "approver",
      description: "Can approve and decline bills. Can manage contacts.",
    },
    {
      label: "Operator",
      value: "viewer",
      description: "Can approve and decline bills. ",
    },
  ];

  const renderListItem = (teammate: ContactType | UserType) => {
    // a signed up user of quickly
    if ("roles" in teammate) {
      const isCurrentUser = teammate.id === user?.id;
      const isOwner = (teammate?.roles || []).includes("owner");
      const disableTeammate = isCurrentUser || isOwner;

      return (
        <div className="flex w-full flex-col flex-wrap justify-between gap-1 sm:flex-row sm:items-center sm:gap-4">
          <div className="flex flex-row items-center gap-2 md:gap-4">
            <Switch
              checked={teammate?.enabled}
              disabled={disableTeammate}
              disabledReason={
                isCurrentUser
                  ? "You cannot disable yourself"
                  : "You do not have the permissions to disable this teammate"
              }
              onCheckedChange={(val: boolean) => {
                handleDisableTeammate({ ...teammate, enabled: val });
              }}
            />
            <div className="hidden sm:block">
              <Avatar user={teammate} />
            </div>
            <div className="flex-1 truncate">
              <div
                className={clsx("truncate text-sm font-semibold ", {
                  "text-gray-500": !teammate?.enabled,
                  "text-gray-900": teammate?.enabled,
                })}
              >{`${teammate.first_name} ${teammate.last_name}`}</div>
              <div
                className={clsx("truncate text-sm", {
                  "text-gray-500": !teammate?.enabled,
                  "text-gray-900": teammate?.enabled,
                })}
              >
                {teammate.email}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <SelectInput
              onChange={(val) => {
                handleChangeRole({ ...teammate, roles: [val.value] });
              }}
              className="!w-60 lg:!w-80"
              disabled={disableTeammate}
              value={
                roleOptions.filter(
                  (r) => teammate.roles?.indexOf(r.value) !== -1
                )
                  ? {
                      label: roleOptions.filter(
                        (r) => teammate.roles?.indexOf(r.value) !== -1
                      )[0]?.label,
                      value: roleOptions.filter(
                        (r) => teammate.roles?.indexOf(r.value) !== -1
                      )[0]?.value,
                    }
                  : null
              }
              wide
              options={roleOptions}
              placeholder="Assign role"
            />

            <Button
              color="transparent"
              icon="trash"
              data-testid="remove-teammate"
              onClick={() => handleRemoveTeammate(teammate)}
              customIconSize="20"
              disabled={disableTeammate}
              loading={Boolean(deleteLoading === teammate.id)}
            />
          </div>
        </div>
      );
    } else {
      // For pending invited users
      return (
        <>
          <div className="flex w-full items-center justify-between space-x-4">
            <Switch disabled checked={true} />
            <div className="hidden sm:block">
              <Avatar user={teammate} />
            </div>
            <div className="flex-1 truncate">
              {teammate.first_name && (
                <div className="truncate text-sm font-bold text-gray-900">{`${teammate.first_name} ${teammate.last_name}`}</div>
              )}
              <div className="truncate text-sm text-gray-900">
                {teammate.email}
              </div>
            </div>

            <div className="flex flex-shrink-0 flex-row items-center gap-x-4">
              <div className="hidden text-sm text-gray-900 sm:block">
                Invite pending
              </div>
              <Button
                color="transparent"
                icon="trash"
                onClick={() => handleRemoveContact(teammate)}
                customIconSize="20"
                disabled={deleteLoading !== null}
                loading={Boolean(deleteLoading === teammate.id)}
              />
            </div>
          </div>
          <div className="mt-2 pl-14 text-sm text-gray-900 sm:hidden">
            Invite pending
          </div>
        </>
      );
    }
  };

  return (
    <div className="UserTable rounded-lg border border-gray-200 bg-white">
      <ul role="list" className="divide-y divide-gray-200">
        {teammates.length > 0 ? (
          teammates?.map((teammate, index) => (
            <li
              key={`${index}+${teammate.id}`}
              className="flex flex-col justify-between px-3 py-4 md:flex-row md:px-6"
            >
              {renderListItem(teammate)}
            </li>
          ))
        ) : (
          <li className="py-6">
            <div className="font-gilroy text-center text-xl font-bold text-gray-900">
              Loading...
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
