export const provinces = [
  { label: "Alberta", value: "AB", country: "CA" },
  { label: "British Columbia", value: "BC", country: "CA" },
  { label: "Manitoba", value: "MB", country: "CA" },
  { label: "New Brunswick", value: "NB", country: "CA" },
  { label: "Newfoundland and Labrador", value: "NL", country: "CA" },
  { label: "Northwest Territories", value: "NT", country: "CA" },
  { label: "Nova Scotia", value: "NS", country: "CA" },
  { label: "Nunavut", value: "NU", country: "CA" },
  { label: "Ontario", value: "ON", country: "CA" },
  { label: "Prince Edward Island", value: "PE", country: "CA" },
  { label: "Quebec", value: "QC", country: "CA" },
  { label: "Saskatchewan", value: "SK", country: "CA" },
  { label: "Yukon", value: "YT", country: "CA" },
  { label: "Alabama", value: "AL", country: "US" },
  { label: "Alaska", value: "AK", country: "US" },
  { label: "Arizona", value: "AZ", country: "US" },
  { label: "Arkansas", value: "AR", country: "US" },
  { label: "California", value: "CA", country: "US" },
  { label: "Colorado", value: "CO", country: "US" },
  { label: "Connecticut", value: "CT", country: "US" },
  { label: "Delaware", value: "DE", country: "US" },
  { label: "Florida", value: "FL", country: "US" },
  { label: "Georgia", value: "GA", country: "US" },
  { label: "Hawaii", value: "HI", country: "US" },
  { label: "Idaho", value: "ID", country: "US" },
  { label: "Illinois", value: "IL", country: "US" },
  { label: "Indiana", value: "IN", country: "US" },
  { label: "Iowa", value: "IA", country: "US" },
  { label: "Kansas", value: "KS", country: "US" },
  { label: "Kentucky", value: "KY", country: "US" },
  { label: "Louisiana", value: "LA", country: "US" },
  { label: "Maine", value: "ME", country: "US" },
  { label: "Maryland", value: "MD", country: "US" },
  { label: "Massachusetts", value: "MA", country: "US" },
  { label: "Michigan", value: "MI", country: "US" },
  { label: "Minnesota", value: "MN", country: "US" },
  { label: "Mississippi", value: "MS", country: "US" },
  { label: "Missouri", value: "MO", country: "US" },
  { label: "Montana", value: "MT", country: "US" },
  { label: "Nebraska", value: "NE", country: "US" },
  { label: "Nevada", value: "NV", country: "US" },
  { label: "New Hampshire", value: "NH", country: "US" },
  { label: "New Jersey", value: "NJ", country: "US" },
  { label: "New Mexico", value: "NM", country: "US" },
  { label: "New York", value: "NY", country: "US" },
  { label: "North Carolina", value: "NC", country: "US" },
  { label: "North Dakota", value: "ND", country: "US" },
  { label: "Ohio", value: "OH", country: "US" },
  { label: "Oklahoma", value: "OK", country: "US" },
  { label: "Oregon", value: "OR", country: "US" },
  { label: "Pennsylvania", value: "PA", country: "US" },
  { label: "Rhode Island", value: "RI", country: "US" },
  { label: "South Carolina", value: "SC", country: "US" },
  { label: "South Dakota", value: "SD", country: "US" },
  { label: "Tennessee", value: "TN", country: "US" },
  { label: "Texas", value: "TX", country: "US" },
  { label: "Utah", value: "UT", country: "US" },
  { label: "Vermont", value: "VT", country: "US" },
  { label: "Virginia", value: "VA", country: "US" },
  { label: "Washington", value: "WA", country: "US" },
  { label: "West Virginia", value: "WV", country: "US" },
  { label: "Wisconsin", value: "WI", country: "US" },
  { label: "Wyoming", value: "WY", country: "US" },
];
