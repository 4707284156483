import { Loader } from "../../../shared/components";

const LoadingERP = () => {
  return (
    <div
      data-testid="loading-erp-page"
      className="LoadingERP flex flex-col gap-4 rounded-xl border border-gray-200 bg-white px-4 py-5"
    >
      <div className="flex w-full items-start justify-start gap-4">
        <div className="w-[54px] rounded-full bg-gray-100 p-3">
          <Loader nolabel size="8" />
        </div>

        <div className="flex-1">
          <div className="flex justify-between">
            <div className="mb-1 h-5 w-[150px] animate-pulse rounded-full bg-gray-200" />
            <div className="mb-1 h-5 w-[150px] animate-pulse rounded-full bg-gray-200" />
          </div>

          <div className="h-4 w-[250px] animate-pulse rounded-full bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default LoadingERP;
