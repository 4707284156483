import * as yup from "yup";
import { BusinessRegistrationE } from "../../features/auth/types";
import { selectOptionSchema } from "./schema";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const businessNumberRegExp = /^\d{3}-?\d{6,7}$/;
const websiteRegExp = new RegExp(
  "^$|^((https?:\\/\\/)?)" + // make "s" in "https" optional
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

// const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailSchema = yup.object({
  email: yup
    .string()
    .matches(emailRegex, "Please enter a valid email address")
    .required("Email is required"),
});

export const inviteUserSchema = yup.object({
  email: yup
    .string()
    .required("Please enter a valid email")
    .matches(emailRegex, { message: "Please enter a valid email address" }),
  first_name: yup.string().optional(),
  last_name: yup.string().optional(),
});

export const loginSchema = yup.object({
  email: yup
    .string()
    .matches(emailRegex, "Please enter a valid email address")
    .required("Email is required"),
});

export const userSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .matches(emailRegex, "Please enter a valid email address")
    .required("Email is required"),
  password: yup.string().min(8).required("Password is required"),
  token: yup.string().optional(),
  invoiceId: yup.string().optional(),
  ref: yup.string().optional(),
  isTeamInvite: yup.boolean().optional(),
  company_name: yup.string().optional(),
});

export const companySignupSchema = yup.object({
  trade_name: yup.string().optional(),
  legal_name: yup.string().required("Legal name is required"),
  has_trade_name: yup.boolean().optional(),
  business_registration: yup
    .mixed<BusinessRegistrationE>()
    .oneOf(Object.values(BusinessRegistrationE) || undefined)
    .required(),
  business_number: yup.string().when("business_registration", {
    is: BusinessRegistrationE.CORPORATION,
    then: () =>
      yup
        .string()
        .matches(
          businessNumberRegExp,
          "Business number should be a 9 or 10 digit number"
        )
        .required("Business number or EIN required"),
    otherwise: () => yup.string().notRequired(),
  }),
  phone: yup.string().when("business_registration", {
    is: BusinessRegistrationE.CORPORATION,
    then: () =>
      yup
        .string()
        .required("Phone number is required")
        .matches(phoneRegExp, "Phone number is invalid"),
    otherwise: () => yup.string().notRequired(),
  }),
  website: yup
    .string()
    .optional()
    .nullable()
    .matches(
      websiteRegExp,
      "Website is invalid URL (e.g. https://website.com)"
    ),
  business_type: selectOptionSchema,
  industry: selectOptionSchema,
  early_pay_intent: yup.bool(),
  subscribe: yup.bool(),
  expected_activity: yup.string().nullable(),
  activity: yup
    .object()
    .shape({
      expected_activity: yup.string().required("Expected activity is required"),
      early_pay_intent: yup.bool().required(),
    })
    .required(),
});

export const userProfileSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .matches(emailRegex, "Please enter a valid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const userProfileCompanySchema = yup.object().shape({
  name: yup.string().required("Company name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, "Please enter a valid email address")
    .optional(),
  phone: yup
    .string()
    .nullable()
    .optional()
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const contactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .trim()
    .lowercase()
    .matches(emailRegex, "Please enter a valid email address")
    // .notOneOf(
    //   [user?.email.toLowerCase()],
    //   "You can't add yourself as a contact!"
    // )
    .required("Please enter a valid email address"),
  phone: yup
    .string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .optional(),
  first_name: yup.string().optional(),
  last_name: yup.string().optional(),
});

export type UserSignupSchemaT = yup.InferType<typeof userSchema>;
export type CompanySignupSchemaT = yup.InferType<typeof companySignupSchema>;
