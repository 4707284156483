"use client";

import SVG from "react-inlinesvg";

export type PropsT = {
  icon: string;
  iconStyle?: string;
  size?: string;
  "data-testid"?: string;
};

export const Icon = (props: PropsT) => {
  const {
    size,
    icon,
    iconStyle = "fill-gray-500",
    "data-testid": dataTestId,
  } = props;

  if (!icon) return null;

  return (
    <SVG
      className={`${icon} ${iconStyle} flex-shrink-0`}
      src={getIcon(icon)}
      width={size || "24"}
      height={size || "24"}
      data-testid={dataTestId || "icon"}
    />
  );
};

const getIcon = (icon: string) => {
  try {
    const path = require(`../../icons/${icon}.svg`).default.src;
    return path;
  } catch (e) {
    console.error(`${icon} is not found:`, e);
    return "";
  }
};
