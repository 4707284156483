import clsx from "clsx";
import { Icon } from "../../Icon";

export type PropsT = {
  onSearch: (query: string) => void;
  label: string;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  wide?: boolean;
};

export const SearchInput = (props: PropsT) => {
  const { onSearch, label, value, setValue, wide } = props;

  return (
    <div
      className={clsx("SearchInput relative h-10 w-full", {
        "max-w-sm": !wide,
      })}
      data-testid="search-input-container"
    >
      <input
        data-testid="search-input"
        name="SearchInput"
        value={value}
        onChange={setValue}
        type="text"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(value);
          }
        }}
        placeholder={label}
        className="flex h-10 w-full overflow-ellipsis rounded-full border border-gray-300 bg-white pl-5 pr-16 text-sm text-gray-900 placeholder:text-gray-500 autofill:text-sm autofill:text-gray-900 focus:border-gray-900 focus:outline-none focus:ring-0"
      />

      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
        {value && (
          <div
            onClick={() => {
              setValue({
                target: {
                  value: "",
                },
              } as React.ChangeEvent<HTMLInputElement>);
              onSearch("");
            }}
            className="group pointer-events-auto mr-2 flex cursor-pointer"
            data-testid="clear-button"
          >
            <Icon
              icon="x-circle"
              size="20"
              iconStyle="fill-gray-400 group-hover:fill-gray-900"
            />
          </div>
        )}
        <Icon icon="magnifying-glass" size="16" iconStyle="fill-gray-900" />
      </div>
    </div>
  );
};
