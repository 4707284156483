import { NextRouter } from "next/router";
import { useEffect, useState } from "react";

export const useQueryStringParser = (router: NextRouter) => {
  const [queryParams, setQueryParams] = useState(router?.query);

  useEffect(() => {
    if (router.isReady && router.query) {
      setQueryParams(router.query);
    }
  }, [router]);

  return queryParams;
};
