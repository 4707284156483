import { useCallback, useState } from "react";
import { getCompany } from "../../../../apis/company";
import { createManualERPConnection } from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import {
  Button,
  InlineAlert,
  Loader,
  TextInput,
} from "../../../shared/components";

type PropsT = {
  onSuccess: () => void;
  onCancel: () => void;
};

const waitForSyncEnd = async (seenSync: boolean = false): Promise<void> => {
  const response = await getCompany();

  if (
    (!seenSync && !response.company.is_syncing) ||
    response.company.is_syncing
  ) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return waitForSyncEnd(response.company.is_syncing);
  }

  return;
};

const SetupSageIntacct = ({ onSuccess, onCancel }: PropsT) => {
  const { getAllOrganizationalEntities, user } = useAuth();
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const [companyId, setCompanyId] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const handleCreateConnection = useCallback(async () => {
    if (!user) return;
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await createManualERPConnection({
        type: "sageIntacct",
        ws_company_id: companyId,
        ws_user: userId,
        ws_password: password,
      });

      if (response.success) {
        setIsLoading(false);
        setIsSyncing(true);
        await waitForSyncEnd();
        await getAllOrganizationalEntities();

        onSuccess();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    user,
    password,
    companyId,
    userId,
    getAllOrganizationalEntities,
    onSuccess,
  ]);

  if (!isSyncing) {
    return (
      <div className="flex flex-col gap-3">
        {isError && (
          <InlineAlert
            type="error"
            title=""
            subtitle="Error connecting, please contact support"
          />
        )}

        <TextInput
          label="Sage Intacct Company ID"
          value={companyId}
          onChange={(e) => setCompanyId(e.target.value)}
          wide
        />
        <TextInput
          label="Web Services User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          wide
        />
        <TextInput
          label="Web Services User Password"
          type="password"
          password
          value={password}
          wide
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex justify-end gap-2">
          <Button onClick={onCancel} label="Back" color="transparent" outline />
          <Button
            onClick={handleCreateConnection}
            loading={isLoading}
            label="Connect"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <Loader size="20" nolabel />
      <div className="mt-2 text-center">
        Connecting to your ERP... <br />
        Please wait while we sync your entities, this may take up to a minute
      </div>
    </div>
  );
};

export default SetupSageIntacct;
