import { useEffect, useState } from "react";
import { archiveAsset, uploadAsset } from "../../../apis/assets";
import { useAuth } from "../../../state/AuthContexts";
import { AssetTypeE } from "../../features/auth/types";

export const useAssetsUpload = () => {
  const { user, company, getCompany } = useAuth();

  const [uploading, setUploading] = useState(false);
  const [removingLoader, setRemovingLoader] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedAsset, setSelectedAsset] = useState<AssetType>();
  const [currentType, setCurrentType] = useState<AssetTypeE>();
  const [documents, setDocuments] = useState<AssetType[]>([]);

  useEffect(() => {
    if (company && company?.Documents) {
      setDocuments(company?.Documents);
    }
  }, [company]);

  const onUploadComplete = async () => {
    if (user?.token) {
      await getCompany(user?.token);
    }
    setUploading(false);
  };

  const handleUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: AssetTypeE
  ) => {
    // e.preventDefault();
    setCurrentType(type);
    setUploading(true);

    if (e.target.files) {
      return uploadAsset({
        type: type,
        files: e.target.files,
        onComplete: onUploadComplete,
      });
    }
  };

  const handleRemove = async (invoiceAsset: AssetType) => {
    if (user) {
      setRemovingLoader(true);
      const request = await archiveAsset(invoiceAsset);

      if (request && request?.success) {
        await getCompany(user?.token);
      }

      setRemovingLoader(false);
      setShowDeletionModal(false);
      setOpenModal(false);
      setSelectedAsset(undefined);
    }
  };

  const onButtonClick = (file: AssetType) => {
    setSelectedAsset(file);
    setOpenModal(true);
  };

  return {
    handleRemove,
    handleUpload,
    onButtonClick,
    setOpenModal,
    setShowDeletionModal,
    setSelectedAsset,
    uploading,
    removingLoader,
    showDeletionModal,
    openModal,
    selectedAsset,
    currentType,
    documents,
  };
};
