import Router from "next/router";
import { useEffect } from "react";

export const useWarnIfUnsavedChanges = (
  changesMade: boolean,
  callback: () => boolean
) => {
  useEffect(() => {
    if (changesMade) {
      const routeChangeStart = () => {
        const ok = callback();

        // if user presses 'cancel', ok = false, and stays on page. if user presses 'ok', stays on page.
        if (!ok) {
          Router.events.emit("routeChangeError");
          throw "Abort route change. Please ignore this error.";
        }
      };

      Router.events.on("routeChangeStart", routeChangeStart);

      return () => {
        Router.events.off("routeChangeStart", routeChangeStart);
      };
    }
  }, [callback, changesMade]);
};
