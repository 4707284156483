import { Button, Icon } from "@repo/ui";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { getUploadLink } from "../../../../apis/assets";
import { archiveInvoice, createInvoice } from "../../../../apis/invoices";
import { useAuth } from "../../../../state/AuthContexts";
import { AssetTypeE } from "../../../features/auth/types";

type PropsT = {
  type: "invoice" | "user" | "company";
  trigger?: boolean;
  hidden?: boolean;
  onComplete?: (urls: { success: boolean; url: string }[]) => void;
  id?: string;
};

export default function Uploader(props: PropsT) {
  const { type, trigger, hidden, onComplete, id = "" } = props;

  const router = useRouter();
  const { user } = useAuth();

  const [files, setFiles] = useState<File[]>([]);
  const [isDropping, setIsDropping] = useState(false);
  const [createdInvoice, setCreatedInvoice] = useState<InvoiceType>();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const [uploadFileArrayIndex, setUploadFileArrayIndex] = useState(0);

  const inputFileRef = useRef<HTMLInputElement>(null);

  // todo: redundant asset helper (see getUploadLink and uploadAsset in api call)
  const handleUploadFiles = async (files: File[]) => {
    if (files && files.length > 0 && user?.token) {
      let newInvoice: InvoiceType | null = null;

      // create new invoice if uploading an invoice; else just upload and pass url
      if (type === "invoice") {
        const newInvoiceReq = await createInvoice();

        if (newInvoiceReq && newInvoiceReq.invoice) {
          setCreatedInvoice(newInvoiceReq.invoice);
          newInvoice = newInvoiceReq.invoice;
        }
      }

      const uploadStatus: Array<{ success: boolean; url: string }> = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setUploadFileArrayIndex(i + 1);

        const response = await getUploadLink({
          type: type,
          fileType: file.type,
          fileName: file.name,
          typeOfAsset:
            type === "invoice" ? AssetTypeE.invoice : AssetTypeE.companyLogo,
          invoiceId: type === "invoice" ? newInvoice?.id : null,
        });

        if (response && response.success) {
          const url = new URL(decodeURIComponent(response.url));
          const formData = new FormData();

          Object.keys(response.fields).forEach((key: string) => {
            formData.append(key, response.fields[key]);
          });

          // Actual file has to be appended last.
          formData.append("Content-Type", file.type);
          formData.append("file", file);
          const xhr = new XMLHttpRequest();

          xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4 && xhr.status === 204) {
              uploadStatus[i] = {
                success: true,
                url: `${url.origin}/${formData.get("key")}`,
              };
              if (uploadStatus.every((upload) => upload.success)) {
                if (onComplete && type !== "invoice") {
                  onComplete(uploadStatus);
                }
                if (type === "invoice") {
                  if (onComplete) onComplete(uploadStatus);
                  if (user?.token) {
                    router.push(
                      `/invoices/upload/${newInvoice?.unique_identifier}`
                    );
                  }
                }
              }
            }
          });

          xhr.open("POST", url.toString(), true);
          xhr.send(formData);
          setFiles([]);
        } else {
          console.error("error: ", response?.message);
          setError(response?.message);
        }

        setIsUploading(false);
      }
    }
  };

  useEffect(() => {
    if (trigger) {
      uploaderClick();
    }
  }, [trigger]);

  const selectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newFiles: File[] = [];
    Array.from(e.target.files || []).forEach((file) => newFiles.push(file));
    setFiles(newFiles);
    handleUploadFiles(newFiles);
    setIsUploading(true);
  };

  const uploaderClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current?.click();
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDropping(false);
    e.stopPropagation();
  };

  const handleDragOver = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDropping(true);
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newFiles: File[] = Array.from(e.dataTransfer.files);

    if (newFiles && newFiles.length > 0) {
      setFiles(newFiles);
      handleUploadFiles(newFiles);
      e.dataTransfer.clearData();
      setIsUploading(true);
    }
    e.stopPropagation();
  };

  const handleCancelUpload = async () => {
    setFiles([]);
    setIsUploading(false);
    setError("");
    if (createdInvoice && user?.token) {
      await archiveInvoice({ invoiceId: createdInvoice.id });
    }
  };

  return (
    <>
      <div data-testid="uploader" className={hidden ? "hidden" : "relative"}>
        {type === "invoice" && (isUploading || error) && (
          <span className="fixed inset-0 z-10 bg-black bg-opacity-20" />
        )}

        <label htmlFor={`uploader-input${id}`} className="hidden">
          Upload file:
        </label>
        <input
          className="hidden"
          type="file"
          accept={
            type === "invoice"
              ? "capture=camera,image/png,image/jpeg,image/jpg,.pdf"
              : "capture=camera,image/png,image/jpeg,image/jpg"
          }
          ref={inputFileRef}
          onChange={selectedFiles}
          id={`uploader-input${id}`}
          data-testid="uploader-input"
          name="file"
          multiple
        />
        <div className="Uploader-Button">
          <button
            onClick={uploaderClick}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            data-testid="uploader-button"
            type="button"
            className={clsx(
              "relative block w-full items-center justify-center rounded-lg border border-dashed bg-white p-2 py-6 text-center hover:bg-gray-100",
              {
                "border-gray-900": isDropping,
                "border-gray-200": !isDropping,
              }
            )}
          >
            <span className="mb-2 inline-flex items-center justify-center">
              <Icon
                icon="arrow-circle-up-duotone"
                iconStyle="fill-gray-900"
                size="40"
              />
            </span>
            <span className="block text-sm font-medium text-gray-500">
              {type === "invoice"
                ? "Drag your invoice here to upload"
                : type === "company"
                  ? "Drag your company logo here to upload"
                  : "Drag your logo here to upload"}
            </span>
            <span className="mt-1 block text-sm font-medium text-gray-900">
              or browse for files
            </span>
          </button>
        </div>
      </div>

      {Boolean(isUploading || error) && (
        <div
          className="absolute left-4 top-4 z-20 flex max-w-sm flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 shadow-lg md:w-[600px]"
          data-testid="uploading"
        >
          <div className="flex w-full flex-shrink-0 flex-row">
            <Icon icon="file-text" iconStyle="fill-gray-900" size="20" />

            <div className="flex flex-1 pl-2">
              {files.map((file, index) => (
                <div
                  key={`${file.name}index`}
                  className="text-sm font-medium text-gray-900"
                >
                  {file.name}
                  {files.length > 1 && index + 1 !== files.length
                    ? ", "
                    : ""}{" "}
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <Button
                icon="x"
                size="sm"
                color="transparent"
                customIconSize="20"
                onClick={() => handleCancelUpload()}
              />
            </div>
          </div>

          {files[0]?.size && (
            <div className="text-xs text-gray-500">
              {files[0]?.size / 1000} {" KB"}
            </div>
          )}

          <div className="bg-primary-500 flex h-2 w-full animate-pulse rounded-full" />

          {files?.length > 1 && (
            <div className="mt-3 text-sm">
              <a href="#" className="text-gray-900">
                File {uploadFileArrayIndex} of {files?.length}
              </a>
            </div>
          )}
          {error && (
            <span className="mt-2 flex text-sm font-semibold text-orange-600">
              <Icon
                icon="warning-circle"
                iconStyle="fill-orange-600 mr-2"
                size="20"
              />
              {error}
            </span>
          )}
        </div>
      )}
    </>
  );
}
