"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import clsx from "clsx";
import * as React from "react";
import { Icon } from "../../Icon";

export type PropsT = {
  label?: string;
  description?: string;
  "data-testid"?: string;
};

const CheckboxInput = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & PropsT
>(
  (
    { className, id, label, description, "data-testid": dataTestId, ...props },
    ref
  ) => {
    const idForInput = id ? id : label;

    return (
      <div
        className={clsx("flex items-center gap-2", {
          "items-start": description,
        })}
      >
        <CheckboxPrimitive.Root
          id={idForInput}
          data-testid={dataTestId || idForInput}
          ref={ref}
          className={clsx(
            "group peer h-4 w-4 shrink-0 rounded-sm border-[1.5px] border-gray-500 bg-white hover:border-gray-900 hover:shadow data-[state=checked]:border-gray-900 data-[state=indeterminate]:border-gray-900 data-[state=checked]:bg-gray-900 data-[state=indeterminate]:bg-gray-900",
            "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-900",
            "disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50 hover:disabled:border-gray-500 hover:disabled:shadow-none",
            className
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            className={clsx("flex items-center justify-center text-current")}
          >
            <Icon
              size="12"
              icon="check"
              iconStyle="fill-white hidden group-data-[state=checked]:block"
            />
            <Icon
              size="12"
              icon="minus"
              iconStyle="fill-white hidden group-data-[state=indeterminate]:block"
            />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>

        {label && (
          <label
            htmlFor={idForInput}
            className={clsx(
              "text-sm font-medium leading-none text-gray-900 first-letter:capitalize peer-hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-gray-400"
            )}
          >
            {label}
            {description && (
              <p className="text-sm font-normal text-gray-500">{description}</p>
            )}
          </label>
        )}
      </div>
    );
  }
);

CheckboxInput.displayName = CheckboxPrimitive.Root.displayName;

export { CheckboxInput };
