import { useCallback, useEffect, useState } from "react";
import { ActivityItem, Drawer, Loader } from "..";
import {
  getCompanyActivity,
  getCompanyTeammates,
} from "../../../../apis/company";

type PropsT = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function ActivityDrawer(props: PropsT) {
  const { open, setOpen } = props;

  const [activity, setActivity] = useState<ActivityT[]>([]);
  const [teammates, setTeammates] = useState<TeammateT[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchActivity = useCallback(async () => {
    const activityReq = await getCompanyActivity();
    setActivity(activityReq?.activity || []);
    const teammateReq = await getCompanyTeammates();
    setTeammates(teammateReq?.teammates || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open) fetchActivity();
  }, [fetchActivity, open]);

  return (
    <Drawer open={open} setOpen={setOpen} title="Activity Log">
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-50 sm:py-0">
        <ul role="list" className="">
          {!loading ? (
            activity.length > 0 ? (
              activity?.map((activityItem) => (
                <ActivityItem
                  key={activityItem.id}
                  activityItem={activityItem}
                  teammates={teammates}
                />
              ))
            ) : (
              <div className="mt-6 flex flex-col items-center">
                <div className="text-lg font-bold text-gray-900">
                  Failed to load your company activity
                </div>
                <div className="mt-1 text-base text-gray-500">
                  Try reloading the page
                </div>
              </div>
            )
          ) : (
            <div className="mt-10">
              <Loader />
            </div>
          )}
        </ul>
      </div>
    </Drawer>
  );
}
