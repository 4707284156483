// "use client";

import clsx from "clsx";
import { DayPicker } from "react-day-picker";
import { Icon } from "../../index";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) => {
  return (
    <DayPicker
      data-testid="calendar"
      showOutsideDays={showOutsideDays}
      className={clsx("Calendar", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-3",
        caption: "flex justify-center relative items-center",
        caption_label: "text-base font-semibold",
        nav: "space-x-1 flex items-center",
        nav_button:
          "group flex outline-gray-900 h-8 w-8 items-center justify-center rounded-full bg-transparent transition-colors duration-300 hover:bg-gray-200",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "rounded-md w-8 text-sm font-medium",
        row: "flex w-full mt-1",
        cell: "h-8 w-8 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: "group outline-gray-900 flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-300 aria-selected:opacity-100 font-semibold",
        day_range_end: "day-range-end",
        day_selected:
          "selected bg-gray-900 text-white hover:text-white hover:bg-gray-900",
        day_today: "text-primary-600",
        day_outside:
          "day-outside opacity-50 aria-selected:bg-accent/50 aria-selected:opacity-30",
        day_disabled: "opacity-50",
        day_range_middle:
          "aria-selected:bg-gray-200 aria-selected:text-gray-900",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <Icon
            icon="caret-left"
            size="18"
            iconStyle="group-hover:fill-gray-900 fill-gray-500"
          />
        ),
        IconRight: () => (
          <Icon
            icon="caret-right"
            size="18"
            iconStyle="group-hover:fill-gray-900 fill-gray-500"
          />
        ),
      }}
      {...props}
    />
  );
};
