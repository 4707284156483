"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import clsx from "clsx";
import * as React from "react";
import { Tooltip } from "../../Tooltip";

export type SwitchProps = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  disabledReason?: string;
  loading?: boolean;
  green?: boolean;
  "data-testid"?: string;
  label?: string;
  description?: string;
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    {
      className,
      green,
      label,
      loading,
      description,
      disabledReason,
      "data-testid": dataTestId = "switch-input",
      ...props
    },
    ref
  ) => {
    const idForInput = props.id || label || "switch";

    const SwitchComponent = () => (
      <div
        className={clsx("Switch flex flex-row gap-2", {
          "items-center": !description,
        })}
      >
        <SwitchPrimitives.Root
          data-testid={dataTestId}
          aria-label={label}
          id={idForInput}
          className={clsx(
            "focus-visible:ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-offset-2",
            "peer inline-flex h-5 w-10 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors",
            "data-[state=unchecked]:bg-gray-300",
            "disabled:cursor-not-allowed disabled:opacity-50",
            className,
            {
              "data-[state=checked]:bg-emerald-400": green,
              "data-[state=checked]:bg-gray-900": !green,
              "mt-1 ": label && description,
              "animate-pulse": loading,
            }
          )}
          {...props}
          ref={ref}
        >
          <SwitchPrimitives.Thumb
            className={clsx(
              "pointer-events-none block size-4 rounded-full bg-white shadow-lg ring-0 transition-transform",
              "data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
            )}
          />
        </SwitchPrimitives.Root>

        <label
          className={clsx(
            "flex flex-col text-base font-medium text-gray-900 hover:cursor-pointer",
            {
              "opacity-50 hover:cursor-default": props.disabled,
            }
          )}
          htmlFor={idForInput}
        >
          {label}
          {description && (
            <span className="text-sm font-normal text-gray-500">
              {description}
            </span>
          )}
        </label>
      </div>
    );

    if (props.disabled && disabledReason) {
      return (
        <Tooltip position="top" title={disabledReason}>
          {SwitchComponent()}
        </Tooltip>
      );
    }

    return SwitchComponent();
  }
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
