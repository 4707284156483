import { capitalizeName } from "../../helpers";

export const dateHandler = (date: Date) => {
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const currentDateTime = new Date();
  const timeDifference = currentDateTime.getTime() - date.getTime();

  const minuteDifference = Math.floor(timeDifference / 1000 / 60);
  const hourDifference = Math.floor(minuteDifference / 60);

  if (minuteDifference < 1) {
    return "Less than 1 min ago";
  } else if (60 > minuteDifference) {
    return rtf.format(-minuteDifference, "minutes");
  } else if (24 > hourDifference) {
    return rtf.format(-hourDifference, "hours");
  } else if (hourDifference >= 24) {
    return capitalizeName(rtf.format(-Math.floor(hourDifference / 24), "days"));
  }
};
