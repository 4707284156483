import * as FullStory from "@fullstory/browser";
import { setUser as setSentryUser } from "@sentry/nextjs";
import Router, { useRouter } from "next/router";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { updateCompany as apiUpdateCompany } from "../apis/company/api";
import { APIRequestUpdateCompanyT } from "../apis/company/types";
import {
  APIResponseGetOrganizationalEntitiesT,
  getOrganizationalEntities,
} from "../apis/erp";
import {
  APIRequestSignupT,
  APIResponseGetCompany,
  APIResponseGetUser,
  APIResponseSignupT,
} from "../src/features/auth/types";

type PropsT = {
  children: ReactNode;
};

type AuthContextType = {
  user: UserType | null;
  getUser: (token: string) => Promise<APIResponseGetUser>;
  updateUser: (
    token: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    avatar_url?: string
  ) => Promise<APIResponse>;
  company: CompanyType | null;
  getCompany: (token: string) => Promise<APIResponseGetCompany>;
  updateCompany: (company: APIRequestUpdateCompanyT) => Promise<APIResponse>;
  // Auth:
  login: (
    email: string,
    password: string,
    token?: string,
    invoiceId?: number
  ) => Promise<APIResponse>;
  signup: (signupData: APIRequestSignupT) => Promise<APIResponseSignupT>;
  logout: () => void;
  getAllOrganizationalEntities: () => Promise<APIResponseGetOrganizationalEntitiesT>;
  organizationalEntities: OrganizationalEntityT[];
  selectedEntity: OrganizationalEntityT | null | undefined;
  setSelectedEntity: (entity: OrganizationalEntityT | null | undefined) => void;
};

const nonAuthRoutes = [
  "/signup",
  "/login",
  "/signup/verify/[[...token]]",
  "/invoice/[invoiceId]",
  "/forgot",
];

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: PropsT) => {
  const router = useRouter();

  const [user, setUser] = useState<UserType | null>(null);
  const [company, setCompany] = useState<CompanyType | null>(null);
  const [organizationalEntities, setOrganizationalEntities] = useState<
    OrganizationalEntityT[]
  >([]);

  const [selectedEntity, setSelectedEntity] = useState<
    OrganizationalEntityT | null | undefined
  >(undefined);

  const logout = useCallback(() => {
    setUser(null);
    setCompany(null);
    setSentryUser(null);
    setOrganizationalEntities([]);
    setSelectedEntity(undefined);

    if (localStorage) {
      localStorage.removeItem("q-state");
      localStorage.removeItem("entityState");
      localStorage.removeItem("invoiceState");
      localStorage.removeItem("billState");
    }

    Router.push("/login");

    if (FullStory.isInitialized()) {
      FullStory.anonymize();
    }
  }, []);

  const getUser = useCallback(
    async (token: string): Promise<APIResponseGetUser> => {
      try {
        const rawResponse = await fetch(`/api/auth/user`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const content = await rawResponse.json();

        // Unauthorized && forbidden to test when token expires
        if (rawResponse.status === 401) {
          logout();

          return { success: false, message: "Token Expired" };
        } else {
          if (content.success) {
            setUser({ ...content.user, token });
            localStorage.setItem(
              "q-state",
              JSON.stringify({ ...content.user, token })
            );

            window &&
              window.Intercom &&
              window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
                email: content.user.email,
                name: content.user.full_name,
                user_id: content.user.id,
                user_hash: content.user.intercom_hash,
                phone: content.user.phone,
                company: {
                  id: content.user.Company.id,
                  name: content.user.Company.name,
                  phone: content.user.Company.phone || content.user.phone,
                  website: content.user.Company.website,
                  industry: content.user.Company.industry,
                  created_at: content.user.Company.created_at,
                  user_type: content.user.Company?.expected_activity
                    ? content.user.Company?.expected_activity
                    : "unknown",
                },
              });

            setSentryUser({
              id: content.user?.id,
              email: content.user?.email,
              username: content.user?.full_name,
              company_name: content.user?.Company?.name,
            });
          } else {
            console.error(content.message);
          }
        }

        return content;
      } catch (error) {
        return { success: false, message: "Something went wrong" };
      }
    },
    [logout]
  );

  const updateUser = async (
    token: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    avatar_url?: string
  ): Promise<APIResponse> => {
    try {
      const rawResponse = await fetch(`/api/auth/user`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user: {
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            avatar_url,
          },
        }),
      });

      const content = await rawResponse.json();
      if (content.success) {
        setUser({ ...content.user, token });
        localStorage.setItem(
          "q-state",
          JSON.stringify({ ...content.user, token })
        );
      } else {
        console.error(content.message);
      }
      return content;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Something went wrong" };
    }
  };

  const getAllOrganizationalEntities = useCallback(async () => {
    const result = await getOrganizationalEntities();

    if (result.success) {
      setOrganizationalEntities(result.organizationalEntities);

      const currentEntity =
        result.organizationalEntities.find((entity) => entity.is_top_level) ||
        result.organizationalEntities?.[0];

      const localEntity = localStorage.getItem("entityState");

      if (localEntity) {
        setSelectedEntity(JSON.parse(localEntity));
      } else {
        if (currentEntity) {
          setSelectedEntity(currentEntity);
          localStorage.setItem("entityState", JSON.stringify(currentEntity));
        }
      }
    }

    return result;
  }, []);

  const getCompany = useCallback(
    async (token: string): Promise<APIResponseGetCompany> => {
      try {
        const companyResponse = await fetch(`/api/company`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (companyResponse.status === 401) {
          logout();
        }

        const content = await companyResponse.json();

        if (content.success) {
          setCompany({
            ...content.company,
          });
        }

        const authToken = JSON.parse(
          localStorage.getItem("q-state") || "{}"
        )?.token;

        if (
          authToken &&
          content.company &&
          organizationalEntities.length === 0
        ) {
          await getAllOrganizationalEntities();
        }

        return content;
      } catch (error) {
        console.error(error);
        return { success: false, message: "Something went wrong" };
      }
    },
    [getAllOrganizationalEntities, logout, organizationalEntities.length]
  );

  const updateCompany = async (company: APIRequestUpdateCompanyT) => {
    try {
      const content = await apiUpdateCompany(company);
      if (content.success && user?.token) {
        await getCompany(user.token);
      } else {
        console.error(content.message);
      }

      return content;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Something went wrong" };
    }
  };

  const login = async (
    email: string,
    password: string,
    token?: string,
    invoiceId?: number
  ): Promise<APIResponse> => {
    try {
      const rawResponse = await fetch(`/api/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, token, invoiceId }),
      });

      const content = await rawResponse.json();

      if (content.success) {
        const companyResponse = await getCompany(content.token);
        const userResponse = await getUser(content.token);

        if (
          userResponse.user?.id &&
          process.env.NEXT_PUBLIC_BUILD_ENV === "production"
        ) {
          FullStory.identify(userResponse.user.id as unknown as string, {
            displayName: userResponse.user.full_name,
            email: userResponse.user.email,
          });
        }

        if (!companyResponse?.company?.OnboardingStatus?.onboarded) {
          router.push("/setup");
        } else if (companyResponse?.company?.expected_activity === "buyer") {
          router.push(
            router.query?.redirect
              ? (router.query.redirect as string)
              : "/bills"
          );
        } else {
          router.push(
            router.query?.redirect
              ? (router.query.redirect as string)
              : "/invoices"
          );
        }
      } else {
        console.error(content.message);
      }

      return content;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Something went wrong" };
    }
  };

  const signup = async ({
    user,
    company,
  }: APIRequestSignupT): Promise<APIResponseSignupT> => {
    try {
      const rawResponse = await fetch(`/api/auth/signup`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user,
          company,
        }),
      });

      const content = await rawResponse.json();
      if (content.success) {
        // Get the full user now
        const [, userResponse] = await Promise.all([
          getCompany(content.token),
          getUser(content.token),
        ]);

        if (
          userResponse.user?.id &&
          process.env.NEXT_PUBLIC_BUILD_ENV === "production"
        ) {
          FullStory.identify(userResponse.user.id as unknown as string, {
            displayName: userResponse.user.full_name,
            email: userResponse.user.email,
          });
        }
      } else {
        console.error(content.message);
      }

      return content;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Something went wrong" };
    }
  };

  const refreshState = useCallback(async () => {
    console.log("Refresh State");

    const existingUser = JSON.parse(localStorage.getItem("q-state") || "{}");
    const existingEntityState = JSON.parse(
      localStorage.getItem("entityState") || "{}"
    );

    if (existingEntityState?.id) {
      setSelectedEntity(existingEntityState);
    }

    if (existingUser && existingUser.id) {
      console.log("Rehydrating...");
      const userResponse = await getUser(existingUser.token);

      if (
        userResponse.user?.verified === false &&
        !router.pathname.includes("/signup/verify")
      ) {
        console.log("Redirecting to signup/verify - refreshState");
        Router.push("/signup/verify");
      }
      await getCompany(existingUser.token);
    } else {
      if (!nonAuthRoutes.includes(router.pathname)) {
        const loginPath = `/login?redirect=${encodeURIComponent(router.asPath)}`;
        Router.push(loginPath);
      }
    }
  }, [getCompany, getUser, router.asPath, router.pathname]);

  useEffect(() => {
    refreshState();
  }, [refreshState]);

  const value = {
    // User:
    user,
    getUser,
    updateUser,
    company,
    getCompany,
    updateCompany,
    getAllOrganizationalEntities,
    organizationalEntities,
    selectedEntity,
    setSelectedEntity,
    // Auth:
    login,
    logout,
    signup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider."
    );
  }
  return context;
};
