import { Button, Modal } from "@repo/ui";
import { useEffect } from "react";

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const VIEWED_NOTICE_LS_KEY = "viewedNotice";

//  show this to all NEW users to fill in their info
export default function UpdateNoticeModal(props: PropsT) {
  const { open, setOpen } = props;

  useEffect(() => {
    const viewedNotice = localStorage.getItem(VIEWED_NOTICE_LS_KEY);

    if (!viewedNotice) {
      setOpen(true);
    }
  }, [setOpen]);

  return (
    <Modal
      open={open}
      setOpen={() => {}}
      title="Compliance agreement"
      hideCloseButton
    >
      <div className="max-w-[416px]" data-testid="update-notice-modal">
        <div className="flex flex-col gap-3 space-y-3 p-3 text-sm text-gray-500 md:p-5 md:text-base">
          <div>
            To maintain regulatory compliance, we&apos;ve made some updates to
            your account settings, and the information we collect.
          </div>
          <div>
            That means, you may need to connect your bank account, and provide
            details about yourself, and any controlling shareholders, and/or
            provide documentation that helps Quickly verify identities.
          </div>
          <div>
            <div className="mb-2 text-base font-semibold text-gray-900">
              Please complete the following:
            </div>
            <div className="text-sm">
              1. Business registration details
              <br />
              2. Registered address
              <br />
              3. Account owner profiles
              <br />
              4. Connect/reconnect bank account
              <br />
            </div>
          </div>
          <div className="flex flex-row-reverse">
            <Button
              color="dark"
              label="Okay, got it"
              data-testid="update-notice-modal-okay"
              onClick={() => {
                localStorage.setItem(VIEWED_NOTICE_LS_KEY, "1");
                setOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
