import { Button, Icon, Modal } from "@repo/ui";
import { useRouter } from "next/router";
import { useAuth } from "../../../../state/AuthContexts";

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lastPage?: string;
};

//  show this to all CURRENT users to re-fill in their info

export default function UpdateProfileModal(props: PropsT) {
  const { open, setOpen } = props;
  const { company } = useAuth();

  const router = useRouter();

  const getIcon = (complete: boolean) =>
    complete ? (
      <Icon icon="check-circle-fill" iconStyle="fill-green-400" size="16" />
    ) : (
      <Icon icon="warning-circle-fill" iconStyle="fill-yellow-400" size="16" />
    );

  return (
    <Modal open={open} setOpen={setOpen} title="Account info required">
      <div className="max-w-[416px]" data-testid="setup-modal">
        <div className="flex flex-col gap-3 p-3 text-sm text-gray-500 md:p-5 md:text-base">
          <div>
            To maintain regulatory compliance, we&apos;ve made some updates to
            your account settings, and the information we collect.
          </div>
          <div>
            That means, you may need to re-connect your bank account, and
            provide details about yourself, and any controlling shareholders,
            and/or provide documentation that helps Quickly verify identities.
          </div>
          <div>
            <div className="mb-2 text-base font-semibold text-gray-900">
              Please ensure you&apos;ve completed the following:
            </div>
            <div className="space-y-1 text-sm">
              <div className="flex flex-row items-center gap-1">
                {getIcon(Boolean(company?.OnboardingStatus?.details))}
                <p>Business registration details</p>
              </div>
              <div className="flex flex-row items-center gap-1">
                {getIcon(Boolean(company?.OnboardingStatus?.address))}
                <p>Registered address</p>
              </div>
              <div className="flex flex-row items-center gap-1">
                {getIcon(Boolean(company?.OnboardingStatus?.shareholders))}
                <p>Account owner profiles</p>
              </div>
              <div className="flex flex-row items-center gap-1">
                {getIcon(company?.OnboardingStatus?.bank === 1)}
                <p>Connect/reconnect bank account</p>
              </div>

              <div className="flex flex-row items-center gap-1">
                {getIcon(Boolean(company?.OnboardingStatus?.contracts))}
                <p>Accept agreement</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3.5 border-t border-gray-200 p-3 md:flex-row md:justify-end md:p-5">
          <Button
            color="white"
            outline
            size="xs"
            label="Maybe later"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button
            color="dark"
            label="Finish account setup"
            size="xs"
            onClick={() => {
              router.push("/setup");
              setOpen(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
