import clsx from "clsx";

export type PropsT = React.HTMLAttributes<HTMLDivElement>;

export const Skeleton = ({ className, ...props }: PropsT) => {
  return (
    <div
      className={clsx("animate-pulse rounded-lg bg-gray-200", className)}
      data-testid="skeleton"
      {...props}
    />
  );
};
