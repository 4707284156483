import { Column } from "@tanstack/react-table";
import clsx from "clsx";
import { ContextMenu } from "../ContextMenu";
import { Icon } from "../Icon";

type DataTableColumnHeaderProps<TData, TValue> =
  React.HTMLAttributes<HTMLDivElement> & {
    column: Column<TData, TValue>;
    title: string;
  };

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return (
      <div
        className={clsx(className)}
        data-testid="DataTableColumnHeader-noSort"
      >
        {title}
      </div>
    );
  }

  const items = [
    {
      label: "Ascending",
      icon: "arrow-up",
      onClick: () => {
        if (column.getIsSorted() === "asc") {
          column.clearSorting();
        } else {
          column.toggleSorting(false);
        }
      },
    },
    {
      label: "Descending",
      icon: "arrow-down",
      onClick: () => {
        if (column.getIsSorted() === "desc") {
          column.clearSorting();
        } else {
          column.toggleSorting(true);
        }
      },
      border: column.getCanHide(),
    },
  ];

  if (column.getCanHide()) {
    items.push({
      label: "Hide",
      icon: "eye-closed",
      onClick: () => column.toggleVisibility(false),
    });
  }

  return (
    <div
      className={clsx("DataTableColumnHeader flex items-center", className)}
      data-testid="DataTableColumnHeader"
    >
      <ContextMenu position="bottom-start" items={items}>
        <div className="-ml-3 flex h-8 flex-row items-center gap-2 rounded-xl px-3 hover:bg-gray-200 data-[state=open]:bg-blue-100">
          <span className="">{title}</span>
          <Icon
            size="16"
            icon={
              column.getIsSorted() === "desc"
                ? "arrow-down"
                : column.getIsSorted() === "asc"
                  ? "arrow-up"
                  : "caret-up-down"
            }
          />
        </div>
      </ContextMenu>
    </div>
  );
}
