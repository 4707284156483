import {
  CompanySignupSchemaT,
  UserSignupSchemaT,
} from "../../shared/schemas/userSchema";

export interface APIResponseGetCompany extends APIResponse {
  company?: CompanyType;
}

export interface APIResponseGetCompanyVendors extends APIResponse {
  vendors?: CompanyType[];
}

export type APIRequestSignupT = {
  user: UserSignupSchemaT;
  subscribe?: boolean;
  company?: CompanySignupSchemaT;
};

export type APIResponseSignupT = APIResponse & {
  user?: UserType;
  contact_id?: number;
};

export interface APIResponseGetUser extends APIResponse {
  user?: UserType;
}

export interface APIResponseGetActivity extends APIResponse {
  activity: ActivityT[];
}

export interface APIResponseCompanySearch extends APIResponse {
  matches?: Array<CompanyType>;
}

export interface APIResponseGetUrl extends APIResponse {
  url: string;
  fields: {
    [key: string]: string;
    bucket: string;
  };
}

export interface APIResponseGetInviteDetails extends APIResponse {
  user: ContactType;
}

export enum UserRoleE {
  OWNER = "owner",
  ADMIN = "admin",
  ACCOUNTANT = "accountant",
  APPROVER = "approver",
  FINANCE = "finance",
}

export enum AssetTypeE {
  companyDocumentIncomeStatementQuarter = "company-document-incomeStatement-quarter",
  companyDocumentBalanceSheetQuarter = "company-document-balanceSheet-quarter",
  companyDocumentBankstatementQuarter = "company-document-bankStatement-quarter",
  companyDocumentOtherQuarter = "company-document-other-quarter",
  companyDocumentIncomeStatementFiscal = "company-document-incomeStatement-fiscal",
  companyDocumentBalanceSheetFiscal = "company-document-balanceSheet-fiscal",
  companyDocumentBankstatementFiscal = "company-document-bankStatement-fiscal",
  companyDocumentOtherFiscal = "company-document-other-fiscal",
  companyDocumentIncomeStatementMonth = "company-document-incomeStatement-month",
  companyDocumentBalanceSheetMonth = "company-document-balanceSheet-month",
  companyDocumentBankstatementMonth = "company-document-bankStatement-month",
  companyDocumentOtherMonth = "company-document-other-month",
  companyDocumentArticleOfIncorporation = "company-document-article-incorporation",
  companyDocumentVoidCheque = "company-document-bank-void-cheque",
  companyLogo = "company-logo",
  userLogo = "user-logo",
  invoice = "invoice",
  invoiceSupport = "invoice-support",
  earlyPayAgreement = "early-payment-agreement",
  manualEPVendors = "manual-ep-vendors",
  manualEPOpenRegister = "manual-ep-open-register",
}

export enum BusinessRegistrationE {
  SOLE_PROPIETOR = "sole-proprietor",
  CORPORATION = "corporation",
}
