import { ComponentProps, useMemo } from "react";
import { APIRequestCreateCompanyERPGLAccountT } from "../../../../apis/erp";
import { DropdownSearchInput } from "../../../shared/components";
import { computeAccountState, defaultIfUndefined } from "../helpers";
import { IntegrationsChangesT } from "../types";

type PropsT = {
  changes?: IntegrationsChangesT;
  type: APIRequestCreateCompanyERPGLAccountT["type"];
  chartOfAccounts: ERPAccountT[];
  entity: OrganizationalEntityT | null;
  field: keyof IntegrationsChangesT;
  handleChange: <K extends keyof IntegrationsChangesT>(
    key: K,
    value: IntegrationsChangesT[K] | null
  ) => void;
};

type DropdownSearchInputProps = ComponentProps<typeof DropdownSearchInput>;

type UseClearingAccountReturnT = {
  dropdown: Pick<DropdownSearchInputProps, "onSelect" | "value">;
  key: string;
};

export const useClearingAccount = ({
  chartOfAccounts,
  entity,
  changes,
  field,
  handleChange,
  type,
}: PropsT): UseClearingAccountReturnT => {
  const changesColumn = changes?.[field];
  const actualColumn = entity?.[field];

  const computedClearingAccount = useMemo(
    () =>
      computeAccountState(
        chartOfAccounts,
        defaultIfUndefined(actualColumn, changesColumn) as string
      ),
    [changesColumn, actualColumn, chartOfAccounts]
  );

  return {
    dropdown: {
      onSelect: (option) => {
        handleChange(field, option?.value);
      },
      value: computedClearingAccount,
    },
    key: `${type}-${computedClearingAccount?.value}`,
  };
};
