export type PropsT = {
  size?: string;
  label?: string;
  nolabel?: boolean;
  height?: string;
  square?: boolean;
  className?: string;
};

export const Loader = (props: PropsT) => {
  const { size, nolabel, label, height, square, className = "" } = props;

  return square ? (
    <div
      className={`Loader flex w-full h-${
        height ? height : "full"
      } animate-pulse rounded-lg bg-gray-100 ${className}`}
      data-testid="loader-square"
    />
  ) : (
    <div
      className={`Loader flex h-full w-full flex-col items-center justify-center gap-2 align-baseline ${className}`}
      data-testid="loader"
    >
      <div
        className={`inline-block text-gray-900 h-${size || "10"} w-${
          size || "10"
        } animate-spin rounded-full border-2 border-solid border-b-gray-200 border-l-gray-200 border-r-gray-900 border-t-gray-200 align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      />
      {!nolabel && (
        <div className="text-base font-medium text-gray-900">
          {label || "Loading..."}
        </div>
      )}
    </div>
  );
};
