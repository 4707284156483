import clsx from "clsx";
import { ChangeEvent, useState } from "react";
import { getAsset } from "../../../../apis/assets";
import { manualEPProcess } from "../../../../apis/company";
import { useAuth } from "../../../../state";
import {
  Button,
  FileUploader,
  Modal,
  TextButton,
  useToast,
} from "../../../shared/components";
import { formatDateOrString } from "../../../shared/helpers";
import { useAssetsUpload } from "../../../shared/hooks";
import { AssetTypeE } from "../../auth/types";

// Poll get asset per ID, until status == 'ready', add a limit of 10 seconds

const pollAssetUntilReady = async (
  assetId: number,
  remainingTries: number = 30
): Promise<AssetType> => {
  const tries = remainingTries - 1;
  if (tries <= 0) {
    throw new Error("Asset never finished uploading");
  }
  const response = await getAsset(assetId);

  if (response.asset.status !== "uploading") {
    return response.asset;
  }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return pollAssetUntilReady(assetId, tries);
};

const ManualUpload = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const { handleUpload, onButtonClick, documents } = useAssetsUpload();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<AssetTypeE>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [clearFilename, setClearFilename] = useState(false);
  const [files, setFiles] = useState<{
    bills: FileList | null;
    vendors: FileList | null;
  }>({ bills: null, vendors: null });

  const handleProcessing = async () => {
    if (!type) return;

    const file =
      type === AssetTypeE.manualEPOpenRegister
        ? files.bills?.[0]
        : files.vendors?.[0];

    if (!file) return;

    setLoading(true);

    const uploadingFileToast = toast({
      loader: true,
      loaderMessage: "Uploading...",
    });

    await handleUpload(
      {
        target: {
          files: [file],
        },
      } as unknown as ChangeEvent<HTMLInputElement>,
      type
    )
      ?.then(async (response) => {
        if (!response?.[0]?.success) throw new Error("Failed to upload file");
        uploadingFileToast.update({
          id: uploadingFileToast.id,
          loaderMessage: "Finalizing upload...",
        });

        // Wait for the assets to be ready
        const assetIds = response.map((asset) => asset.assetId);
        const assetPromises = assetIds.map((assetId) =>
          pollAssetUntilReady(assetId)
        );
        await Promise.all(assetPromises);

        uploadingFileToast.update({
          id: uploadingFileToast.id,
          loaderMessage: "Validating...",
        });

        await manualEPProcess({
          type: type,
          assetIds,
        }).then((response) => {
          if (!response?.success) throw new Error(response.message);

          uploadingFileToast.update({
            id: uploadingFileToast.id,
            loader: false,
            variant: "info",
            title: `Processing your file. We'll notify you when it's done.`,
            className: "w-[450px]",
          });
        });
      })
      .catch((response) => {
        uploadingFileToast.update({
          id: uploadingFileToast.id,
          loader: false,
          variant: "error",
          title: "An error occured with your submission",
          description: response.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setClearFilename(true);
        setFiles({ bills: null, vendors: null });
      });
  };

  const uploaderType = (fileType: AssetTypeE) => {
    const matchingDocuments = documents.filter(
      (document) => document.type === fileType
    );

    return (
      <>
        {matchingDocuments.length > 0 && (
          <div className="mt-2 max-h-48 overflow-scroll">
            {matchingDocuments?.map((file, fileIdx) => {
              formatDateOrString(file.createdAt);
              return (
                <div
                  key={`file-${file.id}-${fileIdx}`}
                  className={clsx(
                    "flex flex-col justify-between border-x border-b border-gray-200 bg-white px-4 py-3 sm:flex-row sm:items-center",
                    {
                      "rounded-t-md border-t": fileIdx === 0,
                      "rounded-b-md": fileIdx === matchingDocuments.length - 1,
                    }
                  )}
                >
                  <TextButton
                    label={
                      file.file_name
                        ? file.file_name
                        : `Uploaded file - #${fileIdx + 1}`
                    }
                    icon="paperclip"
                    onClick={() => {
                      onButtonClick(file);
                    }}
                  />

                  <div className="flex items-center text-xs font-medium text-gray-500">
                    <span>Uploaded {formatDateOrString(file.createdAt)}</span>
                    <span className="mx-2">&#x2022;</span>
                    <span>
                      {" "}
                      {file.user_id === user?.id
                        ? `${user?.first_name || "Unknown"} ${user?.last_name || "User"}`
                        : "Unknown User"}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <h2 className="font-gilroy mt-6 text-lg font-bold text-gray-900">
        Data sync service
      </h2>
      <div className="rounded-lg border border-gray-200 bg-white p-4 sm:p-6">
        <div className="mb-8 flex flex-col gap-3 sm:flex-row">
          <div className="justify-left flex h-8 min-w-24 items-center rounded-lg font-semibold">
            Bills
          </div>

          <div className="flex w-full flex-col">
            <div className="flex w-full flex-row gap-4">
              <div className="w-full">
                <FileUploader
                  type="data"
                  onChange={(e) => {
                    setFiles({
                      ...files,
                      bills: e.target.files,
                    });
                  }}
                  clearFilename={clearFilename}
                  fileName={files.bills?.[0]?.name || ""}
                  loading={loading && AssetTypeE.manualEPOpenRegister === type}
                />
              </div>

              <Button
                onClick={() => {
                  setType(AssetTypeE.manualEPOpenRegister);
                  setShowConfirmationModal(true);
                }}
                label="Load"
                disabled={files.bills === null}
                loading={loading && AssetTypeE.manualEPOpenRegister === type}
              />
            </div>

            {uploaderType(AssetTypeE.manualEPOpenRegister)}
          </div>
        </div>

        <div className="flex flex-col gap-3 sm:flex-row">
          <div className="justify-left flex h-8 min-w-24 items-center rounded-lg font-semibold">
            Suppliers
          </div>
          <div className="flex w-full flex-col">
            <div className="flex w-full flex-row gap-4">
              <div className="w-full">
                <FileUploader
                  type="data"
                  onChange={(e) => {
                    setFiles({
                      ...files,
                      vendors: e.target.files,
                    });
                  }}
                  fileName={files.vendors?.[0]?.name || ""}
                  loading={loading && AssetTypeE.manualEPVendors === type}
                />
              </div>
              <Button
                onClick={() => {
                  setType(AssetTypeE.manualEPVendors);
                  setShowConfirmationModal(true);
                }}
                label="Load"
                disabled={files.vendors === null}
                loading={loading && AssetTypeE.manualEPVendors === type}
              />
            </div>

            {uploaderType(AssetTypeE.manualEPVendors)}
          </div>
        </div>
      </div>

      <Modal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        title="Confirm upload"
      >
        <div
          className="max-w-lg space-y-4 p-3 md:p-5"
          data-testid="confirm-upload-modal"
        >
          <div className="text-base font-normal leading-6 text-gray-500">
            <p className="mb-4">
              You are about to upload a file containing{" "}
              {type === AssetTypeE.manualEPOpenRegister ? `bill` : `supplier`}{" "}
              data to your account.
            </p>

            <p>Please ensure that:</p>
            <ul className="ml-5 list-disc">
              <li>The file format is correct and contains accurate data.</li>
              <li>You have reviewed the information for any errors.</li>
            </ul>

            <p className="mt-4">Do you wish to proceed?</p>
          </div>

          <div className="flex flex-row justify-end space-x-3.5">
            <Button
              color="white"
              label="Cancel"
              outline
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            />
            <Button
              label="Confirm upload"
              loading={loading}
              onClick={() => {
                handleProcessing();
                setShowConfirmationModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManualUpload;
