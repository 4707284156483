"use client";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastLoader,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./Toast";
import { useToast } from "./useToast";

export const Toaster = () => {
  const { toasts } = useToast();

  return (
    <ToastProvider duration={7000}>
      {toasts.map(
        ({
          id,
          title,
          description,
          action,
          variant,
          loader,
          loaderMessage,
          ...props
        }) => {
          return (
            <Toast key={id} {...props}>
              {loader ? (
                <ToastLoader size="4" label={loaderMessage || "Loading..."} />
              ) : (
                <div className="flex flex-col gap-2 pr-2">
                  <div className="mt-0.5 flex flex-row gap-x-2">
                    <ToastIcon variant={variant} />
                    <div>
                      {title && <ToastTitle>{title}</ToastTitle>}

                      {description && (
                        <ToastDescription>{description}</ToastDescription>
                      )}
                    </div>
                  </div>
                  {/* // todo should add in toastAction here? */}
                  {action && <div className="flex justify-end">{action}</div>}
                </div>
              )}
              <ToastClose />
            </Toast>
          );
        }
      )}
      <ToastViewport />
    </ToastProvider>
  );
};
