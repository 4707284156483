// "use client";
import clsx from "clsx";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";
import { Icon } from "../../Icon";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import { Calendar } from "./Calendar";

export type PropsT = {
  date: DateRange | undefined;
  setDate: (daterange: DateRange | undefined) => void;
  label?: string;
  className?: string;
  wide?: boolean;
};

export const DatePickerWithRange = (props: PropsT) => {
  const { date, setDate, label, className, wide } = props;

  return (
    <div
      className={clsx("peer flex w-full flex-col gap-2", className, {
        "w-full md:w-80": !wide,
      })}
      data-testid="datepicker-with-range"
    >
      {label && (
        <label
          htmlFor="DatePickerWithRange"
          className="text-sm font-medium leading-none text-gray-900 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
      )}

      <Popover>
        <PopoverTrigger asChild>
          <button
            id="DatePickerWithRange"
            className={clsx(
              "h-[49px] py-4 pl-4 pr-3",
              "ring-offset-background focus-visible:ring-ring inline-flex items-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
              "hover:bg-accent hover:text-accent-foreground justify-between border border-gray-300 bg-white",
              "justify-start text-left font-normal",
              {
                "w-full": wide,
              }
            )}
          >
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}

            <Icon
              icon="calendar-blank"
              iconStyle="ml-4 fill-gray-900"
              size="20"
            />
          </button>
        </PopoverTrigger>

        <PopoverContent className="mx-4 w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
