import { differenceInCalendarDays } from "date-fns/differenceInCalendarDays";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";

export const calcDaydiff = (invoice: InvoiceType): string => {
  const today = new Date();
  if (invoice?.totals?.dayDiff > 0) {
    return invoice?.totals?.dayDiff.toString();
  }
  let diff = 0;

  const dueDate = new Date(invoice?.due_date);
  const issueDate = new Date(invoice?.issue_date);

  if (dueDate > today) {
    diff = differenceInCalendarDays(dueDate, today);
  } else {
    // default to the payment terms
    diff = differenceInCalendarDays(dueDate, issueDate);
  }

  return diff > 0 ? diff.toString() : "30";
};

export const convertUTCTimezoneToLocal = (utcDate: Date) => {
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
};

// input: date string or date object
// output: Sep 05, 2023
export const formatDateOrString = (date?: Date | string) => {
  if (date) {
    if (typeof date === "string") {
      // This replace Z assumes that we're receiving an ISO timestamp --
      // for some reason sequelize interprets datetimes without timezone
      // as in UTC timezone (00:00 offset), datefns will take this and convert
      // it to the local timezone. We don't want that. We want to assume anything we receive
      // is in the browser's current timezone
      const parsedDate = convertUTCTimezoneToLocal(parseISO(date));
      const t = format(parsedDate, "MMM dd, yyyy");
      return t;
    } else {
      return format(date, "MMM dd, yyyy");
    }
  } else {
    return "N/A";
  }
};

// input: date string or date object
// output: Sept 5, 2023, 6:34 a.m.
export const datetoDTString = (date?: Date | string, hideYear?: boolean) => {
  const options: Intl.DateTimeFormatOptions = hideYear
    ? {
        month: "short",
        day: "numeric",
      }
    : {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

  if (date) {
    return new Date(date).toLocaleDateString("en-CA", options);
  } else {
    return "N/A";
  }
};

// input: date string or date object
// output: September 7, 8:30 a.m.
export const formatDateForRailz = (date?: string | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (date) {
    const formattedDate = new Date(date).toLocaleDateString("en-CA", options);
    const regex = / at /g;
    const updatedString = formattedDate.replace(regex, ", ");
    if (updatedString !== "Invalid Date") {
      return updatedString;
    }
  }
  return "";
};

// input: date string or date object
// output: Sep 05 '23
export const formatDateOrStringShort = (date?: Date | string) => {
  if (date) {
    if (typeof date === "string") {
      // This replace Z assumes that we're receiving an ISO timestamp --
      // for some reason sequelize interprets datetimes without timezone
      // as in UTC timezone (00:00 offset), datefns will take this and convert
      // it to the local timezone. We don't want that. We want to assume anything we receive
      // is in the browser's current timezone
      const parsedDate = convertUTCTimezoneToLocal(parseISO(date));
      const t = format(parsedDate, "MMM dd ''yy");
      return t;
    } else {
      return format(date, "MMM dd ''yy");
    }
  } else {
    return "N/A";
  }
};
