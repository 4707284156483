import { Icon } from "@repo/ui";
import clsx from "clsx";

type PropsT = {
  label: string;
  size?: "sm";
  onClick: () => void;
  active?: boolean;
  "data-testid"?: string;
};

const SelectButton = (props: PropsT) => {
  const {
    label,
    onClick,
    active,
    "data-testid": dataTestId = "select-button",
  } = props;

  return (
    <div
      data-testid={dataTestId}
      onClick={onClick}
      className={clsx(
        "inline-flex w-full cursor-pointer items-center justify-between truncate rounded-lg border border-gray-300 bg-white px-4 py-1 hover:border-gray-900 md:w-fit md:min-w-60",
        "focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0",
        "group relative transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "border-gray-900": Boolean(active),
        }
      )}
    >
      <span className="text-sm text-gray-500">{label}</span>

      <Icon icon={"caret-down"} iconStyle="ml-2 fill-gray-500" size="16" />
    </div>
  );
};

export default SelectButton;
