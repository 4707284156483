import { useState } from "react";
import { useAuth } from "../../../../state";
import {
  Badge,
  SearchInput,
  SelectionBox,
  TextButton,
} from "../../../shared/components";

type PropsT = {
  onEdit: () => void;
  onSelect: () => void;
};

const SelectEntitiesFilter = ({ onEdit, onSelect }: PropsT) => {
  const { organizationalEntities, selectedEntity, setSelectedEntity, company } =
    useAuth();

  const [search, setSearch] = useState("");

  const connection = company?.Connections?.[0];
  const enabledEntities = organizationalEntities.filter((x) => x.is_enabled);
  const topLevelEntity = enabledEntities.find((entity) => entity.is_top_level);

  const otherEntities = enabledEntities.filter(
    (entity) => !entity.is_top_level
  );

  const entityList = search
    ? enabledEntities.filter((entity) =>
        entity.name.toLowerCase().includes(search.toLowerCase())
      )
    : otherEntities;

  return (
    <div
      data-testid="select-entities-filter"
      className="rounded-lg bg-white shadow-lg"
    >
      <div className="flex flex-row justify-between border-b border-gray-200 px-6 py-5">
        <div className="text-lg font-bold">Connected entities</div>
        <TextButton data-testid="edit-entities" label="Edit" onClick={onEdit} />
      </div>
      <div className="w-screen overflow-y-auto px-6 py-5 md:h-[538px] md:w-[403px]">
        <div className="mb-5 flex flex-col gap-2">
          <SearchInput
            onSearch={(text) => setSearch(text)}
            setValue={(e) => setSearch(e.target.value)}
            value={search}
            label="Search"
            wide
          />
          <div className="flex justify-end">
            <TextButton
              label="Select all"
              disabled={selectedEntity === null}
              onClick={() => {
                setSelectedEntity(null);
                localStorage.setItem("entityState", JSON.stringify(null));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {!search && (
            <>
              {topLevelEntity && (
                <SelectionBox
                  wide
                  inline
                  variant="snug"
                  position="left"
                  border="lg"
                  size="sm"
                  title={topLevelEntity.name}
                  value={topLevelEntity.id === selectedEntity?.id}
                  onClick={() => {
                    if (topLevelEntity.id === selectedEntity?.id) {
                      setSelectedEntity(null);
                      localStorage.setItem("entityState", JSON.stringify(null));
                    } else {
                      setSelectedEntity(topLevelEntity);
                      localStorage.setItem(
                        "entityState",
                        JSON.stringify(topLevelEntity)
                      );
                    }
                  }}
                  badge={<Badge color="teal" label="Top level" />}
                  selectionType="radio"
                />
              )}
              {Boolean(connection?.default_to_top_level) && (
                <div
                  data-testid="default-to-top-level-text"
                  className="my-3 flex items-center"
                >
                  <div className="flex-1 border-t border-gray-200"></div>
                  <span className="mx-3 text-sm uppercase text-gray-500">
                    Settings inherited from top level
                  </span>
                  <div className="flex-1 border-t border-gray-200"></div>
                </div>
              )}
            </>
          )}

          {entityList.map((entity) => (
            <SelectionBox
              wide
              inline
              variant="snug"
              position="left"
              border="lg"
              key={entity.id}
              size="sm"
              title={entity.name}
              value={entity.id === selectedEntity?.id}
              onClick={() => {
                if (entity.id === selectedEntity?.id) {
                  setSelectedEntity(null);
                  localStorage.setItem("entityState", JSON.stringify(null));
                } else {
                  setSelectedEntity(entity);
                  localStorage.setItem("entityState", JSON.stringify(entity));
                }
                onSelect();
              }}
              selectionType="radio"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectEntitiesFilter;
