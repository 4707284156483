import * as yup from "yup";
import { selectOptionSchema } from "./schema";

const addressRequired = (message: string) => ({
  is: (ownership: number, isPrimary: boolean) => {
    return ownership >= 25 || isPrimary;
  },
  then: () => yup.string().required(message),
  otherwise: () => yup.string().notRequired(),
});

const ownershipRequired = (message: string) => ({
  is: (isPrimary: boolean) => {
    return !isPrimary;
  },
  then: () =>
    yup
      .number()
      .typeError("Please enter a percentage between 0 and 100")
      .positive("Value must be a positive number")
      .min(0, "Value must be greater than 0")
      .max(100, "Value must be less than or equal to 100")
      .required(message),
  otherwise: () => yup.number().notRequired(),
});

export const createShareholderSchema = yup.object({
  id: yup.number().optional(),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().required("Email is required"),
  job_title: selectOptionSchema,
  ownership_percentage: yup
    .number()
    .when(
      "is_primary",
      ownershipRequired("Please enter an ownership percentage")
    ),
  address_country: yup
    .string()
    .when(
      ["ownership_percentage", "is_primary"],
      addressRequired("Country is required")
    ),
  address_city: yup
    .string()
    .when(
      ["ownership_percentage", "is_primary"],
      addressRequired("City is required")
    ),
  address_line_1: yup
    .string()
    .when(
      ["ownership_percentage", "is_primary"],
      addressRequired("Address is required")
    ),
  address_line_2: yup.string().optional(),
  address_zip: yup
    .string()
    .when(
      ["ownership_percentage", "is_primary"],
      addressRequired("Postal code is required")
    ),
  address_state: yup
    .string()
    .when(
      ["ownership_percentage", "is_primary"],
      addressRequired("Province is required")
    ),
  is_primary: yup.boolean().nullable(),
  updatedAt: yup.string(),
});

export const shareholderListSchema = yup.object({
  shareholders: yup.array().of(createShareholderSchema),
  shareholders_verified: yup.boolean().required(""),
});

export type ShareholderFormDataT = yup.InferType<
  typeof createShareholderSchema & { updatedAt: string }
>;

export type ShareholderFormDataArrayT = yup.InferType<
  typeof shareholderListSchema
>;

export type FieldArrayNameT<T extends string> = `shareholders.${number}.${T}`;

export type ShareholderFieldArrayNamesT = {
  [K in keyof ShareholderFormDataT as FieldArrayNameT<K>]: ShareholderFormDataT[K];
} & { shareholders_verified: boolean };
