import { Button, Icon } from "../../../shared/components";

type PropsT = {
  onSuccess: () => void;
  setSetupType: (type: null | "railz" | "sageIntacct" | "sage300") => void;
};

const SetupSage300 = ({ onSuccess, setSetupType }: PropsT) => {
  const handleContactSupport = async () => {
    window && window.Intercom && window.Intercom("show");
    onSuccess();
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-center">
        <Icon size="120" icon="sage300" iconStyle="-my-8" />
      </div>
      <div className="mb-4 flex flex-col items-center gap-4 font-medium text-gray-500">
        <span className="">
          Connecting a Sage 300 integration to Quickly takes a few more steps.
        </span>
        <span className="text-gray-900">
          If you would like to connect, please contact our support.
        </span>
      </div>

      <div className="flex justify-end gap-2">
        <Button
          onClick={() => {
            setSetupType(null);
          }}
          label="Back"
          color="transparent"
          outline
        />
        <Button
          onClick={handleContactSupport}
          color="gradient"
          label="Contact support"
        />
      </div>
    </div>
  );
};

export default SetupSage300;
