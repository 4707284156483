import { useEffect, useRef, useState } from "react";

export const useInterval = (
  callback: () => void, // callback function to run
  delay: number | null, // 5000 === every 5 seconds
  maxExecutions: number, // how many times to run
  cleanUpFunc: () => void // onFinish callback
) => {
  const savedCallback = useRef<() => void>();
  const [iterations, setIterations] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
        setIterations((iterations) => iterations + 1);
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      setIntervalId(id as unknown as number | null);
      return () => clearInterval(id);
    }
  }, [delay]);

  useEffect(() => {
    if (iterations >= maxExecutions && intervalId !== null) {
      clearInterval(intervalId);
      cleanUpFunc();
    }
  }, [cleanUpFunc, intervalId, iterations, maxExecutions]);
};
