import clsx from "clsx";
import { Icon } from "../Icon";

export type PropsT = {
  items: {
    label: string;
    current: boolean;
    onClick?: () => void;
  }[];
};

export const BreadCrumbs = (props: PropsT) => {
  const { items } = props;

  return (
    <nav className="h-5" aria-label="Breadcrumb" data-testid="BreadCrumbs">
      <ol role="list" className="flex items-center">
        {items.length > 0 &&
          items.map((item, idx) => (
            <li key={item.label} className="flex items-center">
              {idx !== 0 && (
                <Icon icon="caret-right" iconStyle="fill-gray-500" size="20" />
              )}
              <div
                onClick={item?.onClick}
                data-testid={`breadcrumbs-${item.label}`}
                className={clsx("pr-2.5 text-xs font-medium", {
                  "pl-2.5": idx !== 0,
                  "text-gray-900 hover:text-gray-900": item.current,
                  "text-gray-500 hover:text-gray-900": !item.current,
                  "hover:cursor-pointer": item.onClick,
                  "hover:cursor-default": !item.onClick,
                })}
                aria-current={item.current ? "page" : undefined}
              >
                {item.label}
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
};
