import { useToast } from "@repo/ui";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { deleteConnection } from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import { ConfirmModal } from "../../../shared/components";

const SetupIncompleteERPModal = () => {
  const { company, getCompany, user } = useAuth();
  const { toast } = useToast();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [handled, setHandled] = useState(false);
  const [isDeletingConnection, setIsDeletingConnection] = useState(false);
  const connection = company?.Connections?.[0];

  const isERPSetupIncomplete = connection && !connection.is_setup_complete;

  const handleDisconnect = useCallback(async () => {
    if (connection && user?.token) {
      setIsDeletingConnection(true);
      await deleteConnection(connection.id);
      await getCompany(user?.token);
      setIsOpen(false);
      setIsDeletingConnection(false);
      toast({
        variant: "success",
        title: `You've disconnected from ${connection?.connection_type} successfully!`,
      });
    }
  }, [connection, getCompany, toast, user?.token]);

  useEffect(() => {
    if (isERPSetupIncomplete && !handled) {
      setIsOpen(true);
      setHandled(true);
    }
  }, [isERPSetupIncomplete, handled, connection, isOpen]);

  return (
    <ConfirmModal
      hideCloseButton={true}
      disableOverlayClose={true}
      open={isOpen}
      setOpen={setIsOpen}
      title="Incomplete ERP Setup"
      subtitle="Would you like to continue your ERP setup?"
      handleSave={() => {
        router.push("/profile/integrations?edit-entities=true");
        setIsOpen(false);
      }}
      handleClose={handleDisconnect}
      secondaryLoading={isDeletingConnection}
    />
  );
};

export default SetupIncompleteERPModal;
