"use client";

import clsx from "clsx";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { SelectInput } from "../SelectInput";
import { TextInput } from "../TextInput";
import { provinces } from "./constants";
import { isPostalCodeValid, isZipCodeValid } from "./helper";

type PlaceT = {
  place_id: string | null;
  formatted_address: string | null;
  address_components: Array<{
    short_name: string;
    long_name: string;
    types: Array<string>;
  }> | null;
};

export type EditAddressPropsT = {
  parentAddress: AddressTypeT;
  setParentAddress: (address: AddressTypeT) => void;
  errors?: { [key: string]: string };
  wide?: boolean;
};

const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "United States", label: "United States" },
];

export const EditAddress = (props: EditAddressPropsT) => {
  const { parentAddress, setParentAddress, errors, wide = false } = props;
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
      options: {
        types: ["address"],
        componentRestrictions: { country: ["ca", "us"] },
      },
      language: "en",
    });

  const [showAutocompletePopup, setShowAutocompletePopup] = useState(false);
  const [placeDetailsState, setPlaceDetails] = useState({} as PlaceT);
  const [postalCodeError, setPostalCodeError] = useState<string>("");
  const [isCanada, setIsCanada] = useState<boolean>(
    parentAddress?.address_country === "Canada"
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowAutocompletePopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (placePredictions.length) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails: PlaceT) => {
          setPlaceDetails(placeDetails);
        }
      );
    }
  }, [placePredictions, placesService]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    getPlacePredictions({
      input: `${isCanada ? "Canada" : "United States"} ${e.target.value}`,
    });

    setShowAutocompletePopup(true);

    // Reset form if user deletes the address
    if (e.target.value.trim() === "" || e.target.value === null) {
      setPlaceDetails({
        place_id: "",
        address_components: [],
        formatted_address: "",
      });
      setParentAddress({
        address_line_1: "",
        address_line_2: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        address_country: "",
      });
    }
  };

  const handleSelectedAddress = () => {
    setShowAutocompletePopup(false);
    if (placeDetailsState.place_id) {
      // Grabs the address & breaks into consts to assign
      const addressComponents = placeDetailsState.address_components;
      const streetNumber = addressComponents?.find((el) =>
        el.types.includes("street_number")
      )?.short_name;
      const addressTmp = addressComponents?.find((el) =>
        el.types.includes("route")
      )?.short_name;
      const address2Tmp = addressComponents?.find((el) =>
        el.types.includes("subpremise")
      )?.short_name;
      const cityTmp = addressComponents?.find(
        (el) =>
          el.types.includes("locality") || el.types.includes("postal town")
      )?.short_name;
      const zipTmp = addressComponents?.find(
        (el) =>
          el.types.includes("postal_code") || el.types.includes("address_zip")
      )?.short_name;
      const provinceTmp = addressComponents?.find((el) =>
        el.types.includes("administrative_area_level_1")
      )?.short_name;
      const countryTmp = addressComponents?.find((el) =>
        el.types.includes("country")
      )?.long_name;

      const formattedAddress = `${streetNumber || ""} ${addressTmp}`;

      setParentAddress({
        address_line_1: formattedAddress,
        address_line_2: address2Tmp || "",
        address_city: cityTmp || "",
        address_state: provinceTmp || "",
        address_zip: zipTmp || "",
        address_country: countryTmp || "",
      });
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setParentAddress({ ...parentAddress, [e.target.name]: e.target.value });
  };

  return (
    <div
      className={clsx("EditAddress space-y-3", {
        "w-full": wide,
      })}
      data-testid="edit-address-form"
    >
      <SelectInput
        label="Country"
        wide
        required
        showRequired
        data-testid="country-input"
        value={countryOptions.find(
          (option) => option.value === parentAddress.address_country
        )}
        options={countryOptions}
        error={errors && errors["address_country"]}
        onChange={(value) => {
          setParentAddress({
            ...parentAddress,
            address_country: value.value,
            address_city: "",
            address_state: "",
            address_line_1: "",
            address_line_2: "",
            address_zip: "",
          });
          setIsCanada(value.value === "Canada");
          setPostalCodeError("");
        }}
      />
      <div className="relative" ref={inputRef}>
        <TextInput
          label="Address"
          name="address_line_1"
          value={parentAddress.address_line_1}
          onChange={(e) => {
            handleSearch(e);
            onChange(e);
          }}
          required
          showRequired
          autoComplete="street-address"
          wide
          error={errors && errors?.["address_line_1"]}
        />
        {placeDetailsState?.place_id && showAutocompletePopup && (
          <ul className="absolute z-20 mt-1 flex w-full flex-col overflow-hidden rounded-lg bg-white shadow-xl">
            <li
              data-testid="autocomplete-option"
              className="cursor-pointer px-3.5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100"
              onClick={handleSelectedAddress}
            >
              {placeDetailsState?.formatted_address}
            </li>
            {parentAddress.address_line_1.length > 0 && (
              <li
                className="cursor-pointer px-3.5 py-2.5 text-sm text-gray-900 hover:bg-gray-100"
                onClick={() => {
                  setShowAutocompletePopup(false);
                }}
              >
                {`Use "${parentAddress.address_line_1}"`}
              </li>
            )}
          </ul>
        )}
      </div>
      <TextInput
        label="Unit or suite number"
        name="address_line_2"
        wide
        showRequired
        value={parentAddress.address_line_2}
        autoComplete="address-line2"
        onChange={(e) => {
          onChange(e);
        }}
      />
      <TextInput
        label="City"
        data-testid="address_city-input"
        name="address_city"
        wide
        required
        showRequired
        value={parentAddress.address_city}
        autoComplete="address-level2"
        onChange={(e) => {
          onChange(e);
        }}
        error={errors && errors?.["address_city"]}
      />
      <SelectInput
        label={isCanada ? "Province" : "State"}
        data-testid="province-input"
        // autoComplete="address-level1" // todo figure out autocomplete with this select input.
        wide
        required
        showRequired
        value={provinces?.find(
          (province) => province.value === parentAddress?.address_state
        )}
        options={
          parentAddress.address_country === "Canada"
            ? provinces.filter((p) => p.country === "CA")
            : parentAddress.address_country === "United States"
              ? provinces.filter((p) => p.country === "US")
              : provinces
        }
        error={errors && errors?.["address_state"]}
        onChange={(value) => {
          setParentAddress({ ...parentAddress, address_state: value.value });
        }}
      />

      <TextInput
        label={isCanada ? "Postal code" : "Zip code"}
        name="address_zip"
        data-testid="postal-code-input"
        wide
        required
        showRequired
        value={parentAddress.address_zip}
        autoComplete="postal-code"
        onChange={(e) => {
          onChange(e);
          if (e.target.value.trim() === "") {
            setPostalCodeError("");
          }
        }}
        onBlur={(e) => {
          const value = e.target.value.trim();
          if (value) {
            if (isCanada) {
              setPostalCodeError(
                isPostalCodeValid(value)
                  ? ""
                  : "Please enter a valid Postal Code"
              );
            } else {
              setPostalCodeError(
                isZipCodeValid(value) ? "" : "Please enter a valid Zip Code"
              );
            }
          } else {
            setPostalCodeError("");
          }
        }}
        error={postalCodeError || (errors && errors["address_zip"])}
      />
    </div>
  );
};
