"use client";

import clsx from "clsx";
import { useRef } from "react";
import { Icon } from "../../Icon";
import { Loader } from "../../Loader";

export type PropsT = {
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  fileName?: string;
  loading?: boolean;
  type?: "data" | "image" | "both";
  multiple?: boolean;
  error?: string;
  clearFilename?: boolean;
};

export const FileUploader = (props: PropsT) => {
  const {
    multiple = false,
    disabled,
    label,
    fileName,
    loading,
    onChange,
    error,
    clearFilename,
    type = "image",
  } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);

  const dataTypes =
    ".csv,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const imageTypes = "capture=camera,image/png,image/jpeg,image/jpg,.pdf";
  const types =
    type === "data"
      ? dataTypes
      : type === "both"
        ? `${dataTypes},${imageTypes}`
        : imageTypes;

  return (
    <div className="FileUploader">
      {label && (
        <div className="mb-2 text-sm font-medium text-gray-900">{label}</div>
      )}
      {loading ? (
        <div className="h-11">
          <Loader nolabel size="8" />
        </div>
      ) : (
        <div className="relative overflow-visible">
          <input
            className="hidden"
            type="file"
            accept={types}
            ref={inputFileRef}
            onChange={onChange}
            id="file-uploader"
            data-testid="file-uploader"
            name="files"
            multiple={multiple}
            disabled={loading || disabled}
          />
          <button
            className={clsx(
              "UploadButton flex h-11 w-full cursor-pointer items-center overflow-hidden rounded-lg border border-gray-300 bg-white focus:outline-none",
              {
                "cursor-default opacity-50": disabled,
                "border-orange-600 !bg-orange-100": error,
              }
            )}
            onClick={() => {
              if (inputFileRef.current) {
                inputFileRef.current?.click();
              }
            }}
            disabled={loading || disabled}
            data-testid="file-uploader-button"
          >
            <div
              className={clsx(
                "border-r border-gray-200 bg-gray-50 p-3 text-sm font-medium text-gray-900",
                {
                  "border-orange-600 bg-orange-200 !text-orange-600": error,
                }
              )}
            >
              Upload file
            </div>
            <div className="p-2 text-sm text-gray-900">
              {clearFilename ? "No file chosen" : fileName || "No file chosen"}
            </div>
          </button>
          {error && (
            <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
              <Icon
                icon="warning-fill"
                size="16"
                iconStyle="fill-orange-600 mr-2"
              />
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
