import * as yup from "yup";

const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

export const addressSchema = yup.object().shape({
  address_line_1: yup.string().required("Address is required"),
  address_line_2: yup.string(),
  address_city: yup.string().required("City is required"),
  address_state: yup.string().required("Province is required"),
  address_zip: yup.string().when("address_country", {
    is: "Canada",
    then: () =>
      yup
        .string()
        .matches(postalCodeRegex, "Please enter a valid Postal Code")
        .required("Postal code is required"),
    otherwise: () =>
      yup
        .string()
        .matches(zipCodeRegex, "Please enter a valid Zip Code")
        .required("Zip code is required"),
  }),
  address_country: yup.string().required("Country is required"),
});
