"use client";

import clsx from "clsx";
import { format } from "date-fns/format";
import { useState } from "react";
import { SelectSingleEventHandler } from "react-day-picker";
import { Icon } from "../../Icon";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import { Calendar } from "./Calendar";

export type PropsT = {
  id?: string;
  wide?: boolean;
  clearable?: boolean;
  onChange?: (newDate: string) => void;
  value?: string;
  error?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
};

export const DatePicker = (props: PropsT) => {
  const {
    name = "",
    id,
    label,
    value,
    wide,
    clearable,
    onChange,
    error,
    disabled = false,
  } = props;

  const idForInput = id || name || label;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const clearInput = () => {
    onChange?.("");
  };

  const handleSelect = (day: Date) => {
    if (day) onChange?.(day.toString());
    setIsPopoverOpen(false);
  };

  return (
    <div
      className={clsx("Datepicker relative w-full", {
        "w-full md:w-80": !wide,
      })}
      data-testid="datepicker"
    >
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild className="w-full">
          <button
            id={idForInput}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            className="relative outline-none"
          >
            <input
              data-testid="date-input"
              name={name}
              type="text"
              readOnly
              className={clsx(
                "peer z-10 block w-full cursor-pointer appearance-none overflow-ellipsis rounded-lg border border-gray-300 bg-white pb-2.5 pl-[15px] pr-12 pt-4 text-sm text-gray-900 placeholder:text-transparent autofill:text-sm autofill:text-gray-900 focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0",
                {
                  "!border-orange-600 !bg-orange-100": error,
                  "!focus:border-gray-100 !active:border-gray-100 cursor-default !border-gray-100 !bg-gray-100":
                    disabled,
                }
              )}
              placeholder=" "
              value={value ? format(new Date(value), "PPP") : ""}
              disabled={disabled}
              aria-invalid={!!error}
              aria-describedby="datepicker-error"
            />
            <label
              htmlFor={idForInput}
              className={clsx(
                "absolute left-4 top-3.5 z-10 cursor-pointer text-sm text-gray-500 duration-300",
                "origin-[0] -translate-y-3 scale-75 transform peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75",
                "transition-all"
              )}
            >
              {label}
            </label>

            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <Icon icon="calendar-blank" iconStyle="fill-gray-900" size="20" />
            </div>
          </button>
        </PopoverTrigger>

        {value && clearable && (
          <button
            onClick={clearInput}
            className="absolute inset-y-0 right-8 z-20 flex cursor-pointer items-center pr-2"
            data-testid="clear-button"
          >
            <Icon icon="x-circle" size="20" iconStyle="fill-gray-900" />
          </button>
        )}

        {error && (
          <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
            <Icon
              icon="warning-fill"
              size="16"
              iconStyle="fill-orange-600 mr-2"
            />
            {error}
          </div>
        )}
        {!disabled && (
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={value ? new Date(value) : undefined}
              onSelect={handleSelect as SelectSingleEventHandler}
              initialFocus
              defaultMonth={value ? new Date(value) : undefined}
            />
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
};
