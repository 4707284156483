"use client";

import {
  DataTablePagination,
  DataTableViewOptions,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from "@repo/ui";
import {
  ColumnDef,
  flexRender,
  Table as TableType,
} from "@tanstack/react-table";

type DataTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  table: TableType<TData>;
  loading: boolean;
  showSearch?: boolean;
  showViewOptions?: boolean;
  selectable?: boolean;
};

export function DataTable<TData, TValue>({
  columns,
  table,
  loading,
  showSearch,
  showViewOptions,
  selectable,
}: DataTableProps<TData, TValue>) {
  return (
    <div className="flex flex-col gap-4">
      {(showSearch || showViewOptions) && (
        <div className="flex items-center justify-between">
          {showSearch && (
            <TextInput
              label="Search..."
              value={table.getState().globalFilter}
              onChange={(e) => {
                table.setGlobalFilter(String(e.target.value));
              }}
              clearable
              data-testid="search-input"
            />
          )}
          {showViewOptions && <DataTableViewOptions table={table} />}
        </div>
      )}

      <DataTablePagination table={table} selectable={selectable} />

      <div className="overflow-hidden rounded-lg border bg-white">
        <Table>
          <TableHeader className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {loading ? (
              [1, 2, 3].map((n) => (
                <TableRow key={n}>
                  {table.getHeaderGroups()[0].headers.map((header) => (
                    <TableCell key={header.id} className="py-4">
                      <Skeleton className="h-5 min-w-5 !rounded-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <DataTablePagination table={table} selectable={selectable} />
    </div>
  );
}
