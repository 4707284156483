export function computeAccountState(
  chartOfAccounts: ERPAccountT[],
  id?: string | null
) {
  if (id) {
    const foundAccount = chartOfAccounts.find((x) => x.erp_id === id);
    if (!foundAccount) {
      return undefined;
    }

    return {
      label: foundAccount.name,
      value: foundAccount.erp_id,
    };
  }

  return undefined;
}
