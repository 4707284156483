import { Icon, TextButton } from "../../../shared/components";

type Props = {
  onConnect: () => void;
  children?: React.ReactNode;
};

const InactiveERP = ({ onConnect, children }: Props) => {
  return (
    <div
      data-testid="inactive-erp-page"
      className="InactiveERP flex flex-col gap-4 rounded-xl border border-gray-200 bg-white px-4 py-5"
    >
      <div className="flex w-full items-start justify-start gap-4">
        <div className="rounded-full bg-gray-100 p-2">
          <Icon icon="warning-circle" iconStyle="fill-gray-500" size="40" />
        </div>

        <div className="flex-1">
          <div className="flex justify-between">
            <div className="text-base font-semibold text-gray-900">
              No accounting software connected
            </div>

            <TextButton
              size="sm"
              color="gray"
              onClick={onConnect}
              label="Connect"
            />
          </div>

          <div className="text-sm text-gray-500">
            Click connect to get started
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default InactiveERP;
