import * as yup from "yup";

export const validateAndSetErrors = async (
  schema: yup.ObjectSchema<yup.AnyObject>,
  model: unknown,
  setErrors: (err: ErrorT) => void
) => {
  let hasError = false;
  try {
    await schema.validate(model, {
      abortEarly: false,
    });
    return hasError;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      hasError = true;
      const newErrorObj: ErrorT = {};
      err.inner.forEach(({ path, errors }) => {
        if (path) {
          newErrorObj[path] = errors[0];
        }
      });
      setErrors(newErrorObj);
    }
    return hasError;
  }
};

export const selectOptionSchema = yup.object().shape({
  label: yup.string().required("This is a required field"),
  value: yup.string().required("This is a required field"),
});
