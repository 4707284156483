"use client";

import clsx from "clsx";
import { useEffect, useState } from "react";

export type PropsT = {
  open: boolean;
  value?: number;
  onSelect: (newDay: number) => void;
};

export const DayOfMonthSelector = (props: PropsT) => {
  const { open, value, onSelect } = props;

  const [selectedValue, setSelectedValue] = useState(props.value || "");

  useEffect(() => {
    if (!value) setSelectedValue("");
  }, [value]);

  const handleSelect = (day: number) => {
    onSelect(day);
    setSelectedValue(day);
  };

  return (
    <div
      className={clsx(
        "DayOfMonthSelector z-30 mt-2 rounded-lg border border-gray-200 bg-white p-4 shadow-lg",
        {
          absolute: open,
          hidden: !open,
        }
      )}
      data-testid="day-of-month-selector"
    >
      <div className="grid grid-cols-7">
        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
          <div key={day} className={clsx({ "text-gray-900": day > 6 })}>
            <button
              type="button"
              onClick={() => {
                handleSelect(day);
              }}
              data-testid="day-button"
              className={clsx(
                parseInt(day.toString()) ===
                  parseInt(selectedValue.toString()) && "bg-black text-white",
                "flex h-8 w-8 items-center justify-center rounded-full text-sm font-semibold hover:bg-gray-200"
              )}
            >
              {day}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
