import { isContact, isUser } from "./isType";

export const getExternalContacts = (
  contacts: ContactType[],
  companyId: number
) => {
  const usersOrContactsList = contacts.map((c) => {
    return c.User || c;
  });

  const externalCompanies = usersOrContactsList.filter((c) => {
    if (isContact(c)) {
      return c.to_company_id !== companyId;
    } else {
      return c.company_id !== companyId;
    }
  });

  return externalCompanies;
};

export const getCompanyName = (
  contactOrUser: UserType | ContactType | CompanyType | undefined
) => {
  if (isContact(contactOrUser)) {
    return contactOrUser.company_name;
  } else if (isUser(contactOrUser)) {
    return contactOrUser?.Company?.name;
  } else {
    return contactOrUser?.name;
  }
};
