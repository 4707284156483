export function isContact(
  object: UserType | ContactType | CompanyType | InvoiceType | undefined
): object is ContactType {
  if (object === undefined) return false;
  return object && ("converted_id" in object || "inviter_id" in object);
}

export function isCompany(
  object: UserType | ContactType | CompanyType | InvoiceType | undefined
): object is CompanyType {
  if (object === undefined) return false;
  return object && ("legal_name" in object || "tax_rate" in object);
}

export function isUser(
  object: UserType | ContactType | CompanyType | InvoiceType | undefined
): object is UserType {
  if (object === undefined) return false;
  return object && "avatar_url" in object;
}

export const isTeammateContact = (
  teammate: TeammateT
): teammate is ContactType => {
  return "company_name" in teammate;
};
