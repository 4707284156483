import { MouseEvent, ReactNode } from "react";
import { Icon } from "../Icon";

export type PropsT = {
  icon?: string;
  label?: string | ReactNode;
  color?:
    | "gray"
    | "yellow"
    | "green"
    | "indigo"
    | "teal"
    | "purple"
    | "number"
    | "cyan"
    | "slate"
    | "sky"
    | "zinc"
    | "stone"
    | "emerald"
    | "lime"
    | "amber"
    | "red";
  type?: "number" | "button"; // Number, else it makes a badge like in invoicecards
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Badge = (props: PropsT) => {
  const { icon, onClick, label, color, type } = props;

  let textColor = "";
  let backgroundColor = "";
  let iconColor = "";

  switch (color) {
    case "gray": // info
      textColor = "text-gray-900";
      backgroundColor = "bg-gray-100";
      iconColor = "fill-gray-800";
      break;
    case "yellow": // pending
      textColor = "text-yellow-800";
      backgroundColor = "bg-yellow-100";
      iconColor = "fill-yellow-800";
      break;
    case "green": // success
      textColor = "text-green-800";
      backgroundColor = "bg-green-100";
      iconColor = "fill-green-800";
      break;
    case "indigo":
      textColor = "text-indigo-800";
      backgroundColor = "bg-indigo-100";
      iconColor = "fill-indigo-800";
      break;
    case "teal":
      textColor = "text-teal-900";
      backgroundColor = "bg-teal-100";
      iconColor = "fill-teal-900";
      break;
    case "purple":
      textColor = "text-purple-800";
      backgroundColor = "bg-purple-100";
      iconColor = "fill-purple-800";
      break;
    case "number":
      textColor = "text-white";
      backgroundColor = "bg-red-600";
      iconColor = "fill-red-800";
      break;
    case "red":
      textColor = "text-red-800";
      backgroundColor = "bg-red-100";
      iconColor = "fill-red-800";
      break;
    case "zinc":
      textColor = "text-zinc-800";
      backgroundColor = "bg-zinc-100";
      iconColor = "fill-zinc-800";
      break;
    case "stone":
      textColor = "text-stone-800";
      backgroundColor = "bg-stone-100";
      iconColor = "fill-stone-800";
      break;
    case "sky":
      textColor = "text-sky-800";
      backgroundColor = "bg-sky-100";
      iconColor = "fill-sky-800";
      break;
    case "cyan":
      textColor = "text-emerald-800";
      backgroundColor = "bg-emerald-100";
      iconColor = "fill-emerald-800";
      break;
    case "lime":
      textColor = "text-lime-800";
      backgroundColor = "bg-lime-100";
      iconColor = "fill-lime-800";
      break;
    case "amber":
      textColor = "text-amber-800";
      backgroundColor = "bg-amber-100";
      iconColor = "fill-amber-800";
      break;
    default:
      textColor = "text-red-800";
      backgroundColor = "bg-red-100";
      iconColor = "fill-red-800";
  }

  return (
    <>
      {type === "number" ? (
        <div
          className={`Badge flex ${label ? "h-5 w-5" : "h-4 w-4"} items-center justify-center rounded-full ${backgroundColor}`}
          data-testid="badge-number"
        >
          <span className={`text-xs ${textColor}`}>{label}</span>
          <span className="sr-only">{label}</span>
        </div>
      ) : type === "button" ? (
        <button
          className={`Badge flex w-fit items-center space-x-1 rounded-md px-2.5 py-1 hover:shadow ${backgroundColor}`}
          onClick={onClick}
          data-testid="badge-button"
        >
          <span
            data-testid="badge-label"
            className={`max-w-[200px] truncate whitespace-nowrap text-xs font-medium ${textColor}`}
          >
            {label}
          </span>
          <span className="sr-only">{label}</span>
          <Icon icon="x" size="13" iconStyle={`${iconColor}`} />
        </button>
      ) : (
        <div
          className={`Badge flex h-[22px] w-fit items-center rounded-md px-2.5 py-0.5 ${backgroundColor}`}
          data-testid={label || "badge"}
        >
          {icon && (
            <Icon icon={icon} iconStyle={`${iconColor} mr-1`} size="13" />
          )}
          <span
            className={`whitespace-nowrap text-xs font-medium ${textColor}`}
            onClick={onClick}
          >
            {label}
          </span>
        </div>
      )}
    </>
  );
};
