const handleLogout = () => {
  localStorage.removeItem("q-state");
  localStorage.removeItem("invoiceState");
  localStorage.removeItem("billState");
  window.location.replace("/login");
};

export async function fetchWithAuth(url: string, config: RequestInit) {
  try {
    const authToken = JSON.parse(
      localStorage.getItem("q-state") || "{}"
    )?.token;

    if (!authToken) throw "Unauthorized";

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };

    config.headers = {
      ...config.headers,
      ...headers,
    };

    const req = await fetch(`/api${url}`, config);
    const content = await req.json();

    if (!req.ok) {
      if (req.status === 401) {
        handleLogout();
        return;
      } else if (req.status === 400) {
        return content;
      }

      throw new Error(content.message || "Something went wrong");
    }

    return content;
  } catch (error: unknown) {
    console.error(error);

    if (error instanceof Error)
      return {
        success: false,
        message: error.message,
      };

    return {
      success: false,
      message: "Something went wrong",
    };
  }
}

export async function fetchWithOutAuth(url: string, config: RequestInit) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  config.headers = {
    ...config.headers,
    ...headers,
  };

  try {
    const req = await fetch(`/api${url}`, config);
    const content = await req.json();

    if (!req.ok) {
      if (req.status === 400) {
        return content;
      }
      throw new Error(content.message || "Something went wrong");
    }

    return content;
  } catch (error: unknown) {
    console.error(error);

    if (error instanceof Error)
      return {
        success: false,
        message: error.message,
      };

    return {
      success: false,
      message: "Something went wrong",
    };
  }
}
