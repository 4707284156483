import clsx from "clsx";
import { Icon } from "../Icon";

export type PropsT = {
  tabs: {
    name: string;
    current: boolean;
    icon?: string;
    status: Array<string>;
    emptyStatus?: string;
  }[];
  onClick: (status: string) => void;
};

export const HorizontalStepper = (props: PropsT) => {
  const { tabs, onClick } = props;

  return (
    <nav
      className="flex flex-wrap gap-x-4 whitespace-nowrap"
      aria-label="Tabs"
      data-testid="horizontal-stepper"
    >
      {tabs.map((tab) => (
        <button
          type="button"
          key={tab.name}
          onClick={() => onClick(tab.status[0])}
          className={clsx(
            tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-900",
            "group relative flex h-10 items-center text-sm font-medium outline-none sm:px-3"
          )}
          role="tab"
          aria-current={tab.current ? "page" : undefined}
        >
          {tab.icon && (
            <Icon
              iconStyle={
                tab.current
                  ? "text-gray-900 mr-2"
                  : "text-gray-500 group-hover:text-gray-900 mr-2"
              }
              icon={tab.icon}
            />
          )}
          {tab.name}
          {tab.current ? (
            <div className="absolute inset-x-0 bottom-0 -mb-[0.5px] h-[2px] w-full rounded-tl-full rounded-tr-full bg-gray-900" />
          ) : (
            <div className="absolute inset-x-0 bottom-0 -mb-[0.5px] h-[2px] w-full rounded-tl-full rounded-tr-full bg-transparent transition-colors duration-300 group-hover:bg-gray-900" />
          )}
        </button>
      ))}
    </nav>
  );
};
