import { ReactNode } from "react";
import { Accordion } from "../../../shared/components";

type PropsT = {
  title: string;
  complete: boolean;
  children: ReactNode;
  disabled?: boolean;
  "data-testid"?: string;
  disabledReason?: string;
};

const IntegrationAccordion = ({
  title,
  complete,
  children,
  disabled = false,
  disabledReason = "",
  "data-testid": dataTestId = "integration-accordion",
}: PropsT) => {
  return (
    <Accordion
      data-testid={dataTestId}
      defaultOpen={false}
      disabled={disabled}
      headerIcon={
        disabled ? "" : complete ? "check-circle-fill" : "warning-circle-fill"
      }
      headerIconStyle={
        disabled ? "" : complete ? "fill-emerald-400" : "fill-yellow-400"
      }
      title={title}
      disabledMessage={disabledReason}
    >
      {children}
    </Accordion>
  );
};

export default IntegrationAccordion;
