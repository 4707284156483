import { TextButton } from "../../../shared/components";

type PropsT = {
  message: string;
};

const ContactSupportMessage = ({ message }: PropsT) => {
  return (
    <span>
      {message}
      <TextButton
        label="Please contact support."
        color="error"
        size="base"
        onClick={() => {
          window && window.Intercom && window.Intercom("show");
        }}
      />
    </span>
  );
};

export default ContactSupportMessage;
