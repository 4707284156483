import clsx from "clsx";
import { ContextMenu, Icon } from "..";

type PropsT = {
  label?: string;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  menuItems?: ItemT[];
};

const MoreActions = (props: PropsT) => {
  const { label = "More actions", menuItems, size } = props;

  return (
    <ContextMenu items={menuItems} position="bottom-end">
      <div
        className={clsx(
          "group relative inline-flex items-center whitespace-nowrap transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none",
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-200",
          "rounded-full border border-gray-200",
          {
            "h-[34px] px-[14px]": size === "xs",
            "h-[37px] px-4": size === "sm",
          }
        )}
        data-testid="more-actions-button"
      >
        <span
          className={clsx("text-gray-900", {
            "text-xs font-medium": size === "xs",
            "text-sm font-medium": size === "sm",
          })}
        >
          {label}
        </span>

        <Icon icon="caret-down" iconStyle="ml-2 fill-gray-900" size="16" />
      </div>
    </ContextMenu>
  );
};

export default MoreActions;
